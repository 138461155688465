<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">location_on</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">Logradouro</v-toolbar-title>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs6 v-if="$store.state.usuario.site_pais == 'BR'">
            <v-subheader>Informe o CEP e clique na lupa ou tecle ENTER</v-subheader>
            <v-text-field
              autofocus
              v-model="cep.cep"
              label="CEP"
              v-mask="'#####-###'"
              hint="Obrigatório"
              :rules="[validacao.cep == false || validacao.cep, (value) => !!value || 'Obrigatório.', (value) => value.length == 9 || 'Deve conter 8 dígitos numéricos']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              append-icon="search"
              @keyup="buscarCep"
              @keyup.enter="buscarCep"
              @click:append="buscarCep"
              @change="validacao.cep = false"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 v-if="$store.state.usuario.site_pais != 'BR'">
            <v-text-field
              autofocus
              v-model="cep.cep"
              label="CEP"
              v-mask="'####-###'"
              hint="Obrigatório"
              :rules="[validacao.cep == false || validacao.cep, (value) => !!value || 'Obrigatório.', (value) => value.length == 8 || 'Deve conter 7 dígitos numéricos']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.cep = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6>
            <v-btn fab v-if="moduloAssinaturas" class="mt-10" @click="cep.entrega = cep.entrega == 1 ? 0 : 1">
              <v-icon :color="cep.entrega ? 'blue lighten-1' : 'blue-grey lighten-3'" :title="(cep.entrega ? 'Desmarcar' : 'Marcar') + ' como Endereço de Entrega'"
                >local_shipping</v-icon
              >
            </v-btn>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              :disabled="isUpdating"
              v-model="idUF"
              :items="ufs"
              :filter="filtrarUFs"
              hint="Obrigatório"
              :rules="[(value) => !!value || 'Obrigatório.']"
              :label="'Selecione o ' + ($store.state.usuario.site_pais == 'BR' ? 'Estado' : 'Distrito')"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              :disabled="isUpdating"
              v-model="cep.idMunicipio"
              :items="municipios"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.idMunicipio == false || validacao.idMunicipio, (value) => !!value || 'Obrigatório.']"
              :label="'Selecione o ' + ($store.state.usuario.site_pais == 'BR' ? 'Município' : 'Concelho')"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              :no-data-text="'Selecione um ' + ($store.state.usuario.site_pais == 'BR' ? 'Estado' : 'Distrito')"
              @change="atualizarRoteiro"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              v-model="cep.bairro"
              :label="$store.state.usuario.site_pais == 'BR' ? 'Nome do Bairro' : 'Nome da Freguesia/Localidade'"
              maxlength="60"
              hint="Obrigatório"
              :rules="[
                validacao.bairro == false || validacao.bairro,
                (value) => !!value || 'Obrigatório.',
                (value) => valida_nome(value) || ($store.state.usuario.site_pais == 'BR' ? 'Bairro inválido' : 'Freguesia/Localidade inválida'),
                (value) => value.length > 1 || 'Preencha o nome ' + ($store.state.usuario.site_pais == 'BR' ? 'do bairro' : 'da freguesia/localidade')
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="
                cep.bairro = pLetra(cep.bairro);
                atualizarRoteiro();
              "
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md2>
            <v-autocomplete
              :disabled="isUpdating"
              v-model="cep.idTipo"
              :items="tipos"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.idTipo == false || validacao.idTipo, (value) => !!value || 'Obrigatório.']"
              label="Selecione o Tipo de Logradouro"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              @change="atualizarRoteiro"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md10>
            <v-text-field
              v-model="cep.logradouro"
              label="Logradouro"
              maxlength="100"
              hint="Obrigatório"
              :rules="[validacao.logradouro == false || validacao.logradouro, (value) => !!value || 'Obrigatório.', (value) => valida_nome(value) || 'Logradouro inválido']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="
                cep.logradouro = pLetra(cep.logradouro);
                atualizarRoteiro();
              "
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-container fluid grid-list-md v-if="cep.entrega && moduloAssinaturas">
            <v-layout wrap>
              <v-flex xs12>
                <h4 class="overline font-weight-black font-italic">Faixa</h4>
                <v-divider></v-divider>
              </v-flex>

              <v-flex xs6 md3>
                <v-text-field
                  autofocus
                  v-model="cep.nroMinimo"
                  label="Número Mínimo"
                  v-mask="'#####'"
                  hint="Obrigatório"
                  :rules="[
                    validacao.nroMinimo == false || validacao.nroMinimo,
                    (value) => (parseInt(value) <= parseInt(cep.nroMaximo) && parseInt(value) <= 99999) || 'Valor Mínimo deve ser inferior ao Valor Máximo.'
                  ]"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.nroMinimo = false"
                ></v-text-field>
              </v-flex>

              <v-flex xs6 md3>
                <v-text-field
                  autofocus
                  v-model="cep.nroMaximo"
                  label="Número Máximo"
                  v-mask="'#####'"
                  hint="Obrigatório"
                  :rules="[
                    validacao.nroMaximo == false || validacao.nroMaximo,
                    (value) => (parseInt(value) >= parseInt(cep.nroMinimo) && parseInt(value) <= 99999) || 'Valor Máximo deve ser superior ao Valor Mínimo.'
                  ]"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.nroMaximo = false"
                ></v-text-field>
              </v-flex>

              <v-flex xs6>
                <v-text-field
                  v-model="cep.faixa"
                  label="Descrição"
                  maxlength="100"
                  :rules="[validacao.faixa == false || validacao.faixa, (value) => valida_nome(value) || 'Faixa inválida']"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  @change="
                    cep.faixa = pLetra(cep.faixa);
                    validacao.faixa = false;
                  "
                  autocomplete="disabled"
                  :name="Math.random()"
                  title="Para distinguir rotas diferentes no mesmo logradouro, ex: Pares/Ímpares"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>

          <v-flex xs6 md3 v-if="cep.entrega && moduloAssinaturas">
            <v-autocomplete
              v-model="cep.roteiro"
              :items="itensRoteiro"
              :filter="filtrarObjetos"
              label="Tipo de Roteiro"
              item-text="nome"
              item-value="id"
              no-data-text
              hint="Obrigatório"
              :rules="[validacao.roteiro == false || validacao.roteiro, (value) => (value != null && value >= 0) || 'Obrigatório.']"
              @change="validacao.roteiro = false"
            ></v-autocomplete>
          </v-flex>

          <v-container fluid grid-list-md v-if="cep.entrega && cep.roteiro != 1 && moduloAssinaturas">
            <v-layout wrap>
              <v-flex xs12>
                <h4 class="overline font-weight-black font-italic">Roteiro de Assinaturas</h4>
                <v-divider></v-divider>
              </v-flex>

              <v-flex xs12 md6>
                <v-autocomplete
                  :disabled="isUpdating"
                  v-model="cep.idEntregador"
                  :items="entregadores"
                  :filter="filtrarObjetos"
                  hint="Obrigatório"
                  :rules="[validacao.idEntregador == false || validacao.idEntregador]"
                  label="Selecione o Entregador"
                  item-text="nome"
                  item-value="id"
                  autocomplete="disabled"
                  :name="Math.random()"
                  no-data-text
                  @change="validacao.idEntregador = false"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 md6>
                <v-autocomplete
                  :disabled="isUpdating"
                  v-model="cep.idRota"
                  :items="rotas"
                  :filter="filtrarObjetos"
                  hint="Obrigatório"
                  :rules="[validacao.idRota == false || validacao.idRota]"
                  label="Selecione a Rota"
                  item-text="nome"
                  item-value="id"
                  autocomplete="disabled"
                  :name="Math.random()"
                  no-data-text
                  @change="validacao.idRota = false"
                ></v-autocomplete>
              </v-flex>
            </v-layout>
          </v-container>

          <v-container fluid grid-list-md v-if="cep.entrega && cep.roteiro != 0 && moduloAssinaturas">
            <v-layout wrap>
              <v-flex xs12>
                <h4 class="overline font-weight-black font-italic">Roteiro de Venda Avulsa</h4>
                <v-divider></v-divider>
              </v-flex>

              <v-flex xs12 md6>
                <v-autocomplete
                  :disabled="isUpdating"
                  v-model="cep.idEntregadorVA"
                  :items="entregadoresVA"
                  :filter="filtrarObjetos"
                  hint="Obrigatório"
                  :rules="[validacao.idEntregadorVA == false || validacao.idEntregadorVA]"
                  label="Selecione o Entregador"
                  item-text="nome"
                  item-value="id"
                  autocomplete="disabled"
                  :name="Math.random()"
                  no-data-text
                  @change="validacao.idEntregadorVA = false"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 md6>
                <v-autocomplete
                  :disabled="isUpdating"
                  v-model="cep.idRotaVA"
                  :items="rotasVA"
                  :filter="filtrarObjetos"
                  hint="Obrigatório"
                  :rules="[validacao.idRotaVA == false || validacao.idRotaVA]"
                  label="Selecione a Rota"
                  item-text="nome"
                  item-value="id"
                  autocomplete="disabled"
                  :name="Math.random()"
                  no-data-text
                  @change="validacao.idRotaVA = false"
                ></v-autocomplete>
              </v-flex>
            </v-layout>
          </v-container>

          <v-flex xs6>
            <v-switch v-model="cep.situacao" :label="`${cep.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn color="orange lighten-2 white" id="btn-rsn" style="color: white" @click="retornar"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color: white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color: white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue white"
        style="color: white"
        id="btn-rsn"
        outlined
        @click="salvar('true')"
        v-if="direitoCriar && !telaCliente"
      >
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import axios from "axios";
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      cep: {
        id: "",
        idMunicipio: null,
        cep: "",
        bairro: "",
        idTipo: null,
        logradouro: "",
        faixa: "",
        nroMinimo: 0,
        nroMaximo: 99999,
        idEntregador: null,
        idRota: null,
        entrega: this.moduloAssinaturas ? 1 : 0,
        roteiro: 0,
        idEntregadorVA: null,
        idRotaVA: null,
        situacao: 1
      },
      validacao: {
        id: false,
        idMunicipio: false,
        cep: false,
        bairro: false,
        idTipo: false,
        logradouro: false,
        faixa: false,
        nroMinimo: false,
        nroMaximo: false,
        idEntregador: false,
        idRota: false,
        entrega: false,
        roteiro: false,
        idEntregadorVA: false,
        idRotaVA: false,
        situacao: false,
        mensagemErro: false
      },
      idUF: null,
      ufs: [],
      municipios: [],
      entregadores: [],
      rotas: [],
      entregadoresVA: [],
      rotasVA: [],

      tipos: [],

      itensRoteiro: [
        { id: 0, nome: "Assinaturas" },
        { id: 1, nome: "Venda Avulsa" },
        { id: 2, nome: "Ambas" }
      ],

      idSitePrincipal: null,
      direitoCriar: false,
      telaCliente: false,
      elementoValid: true,
      id: "",
      snackbar: false,
      snackErro: false,
      mensagem: "",

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async carregarItem() {
      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

      this.isUpdating = false;
      this.bloqueiaBotoes = true;
      if (this.$route.path.indexOf("/cep") == -1) {
        //* Se foi chamado da tela de clientes, é inclusão
        this.id = "";
        this.telaCliente = true;
      } else if (!this.idCEP) {
        this.id = "";
      } else {
        this.id = this.idCEP;
      }

      //* Verifica se o usuário logado possui direitos
      this.$store.state.usuario.direitos.forEach((element) => {
        var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_CEPS_CRIAR") {
          this.direitoCriar = true;
        }
      });

      await this.carregarUFs();
      await this.carregarTiposLogradouros();
      await this.carregarRotas("ASS");
      await this.carregarEntregadores("ASS");

      await this.carregarRotas("VA");
      await this.carregarEntregadores("VA");
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_CEPS_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_CEPS_EDITAR"]);
        try {
          //* Carrega o cep
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cep/" + this.id);
          if (!response.data) {
            this.$router.push((this.rota.includes("/assinaturas") ? "/assinaturas/" : "/administrativo/") + "ceps");
            return;
          }
          this.cep = response.data;
          this.idUF = this.cep.idUF;
        } catch (e) {
          this.isUpdating = true;
          this.mensagem = "Ocorreu um erro ao carregar os dados logradouro";
          this.snackErro = true;
        }
      }
      setTimeout(() => {
        this.bloqueiaBotoes = false;
      }, 1000);
    },
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      var erro = false;
      if (this.cep.entrega) {
        if (this.cep.roteiro != 1) {
          //* Se é roteiro de assinaturas ou ambos
          if (!this.cep.idEntregador) {
            this.validacao.idEntregador = "Entregador deve ser informado";
            erro = true;
          }
          if (!this.cep.idRota) {
            this.validacao.idRota = "Rota deve ser informada";
            erro = true;
          }
        } else if (this.cep.roteiro != 0) {
          //* Se é roteiro de venda avulsa ou ambos
          if (!this.cep.idEntregadorVA) {
            this.validacao.idEntregadorVA = "Entregador deve ser informado";
            erro = true;
          }
          if (!this.cep.idRotaVA) {
            this.validacao.idRotaVA = "Rota deve ser informada";
            erro = true;
          }
        }
        if (parseInt(this.cep.nroMinimo) > parseInt(this.cep.nroMaximo)) {
          this.validacao.nroMinimo = "Valor Mínimo deve ser inferior ao Valor Máximo";
          erro = true;
        }
      } else {
        this.cep.idEntregador = null;
        this.cep.idRota = null;
        this.cep.idEntregadorVA = null;
        this.cep.idRotaVA = null;
      }
      if (!this.$refs.formElemento.validate() || erro) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      if (this.cep.roteiro == 0) {
        this.cep.idEntregadorVA = null;
        this.cep.idRotaVA = null;
      }
      if (this.cep.roteiro == 1) {
        this.cep.idEntregador = null;
        this.cep.idRota = null;
      }

      this.isUpdating = true;

      try {
        var retorno = "";
        //se tiver id é um update
        var cepCommit = Object.assign({}, this.cep);
        cepCommit.cep = util.somenteNumeros(cepCommit.cep);
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cep/" + this.id, cepCommit);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/cep?idSite=" + this.idSitePrincipal, cepCommit);
          this.id = retorno.data.id;
          this.cep.id = retorno.data.id;
        }
        if (retorno.data.existe) {
          if (!this.telaCliente) {
            this.mensagem = "CEP já cadastrado! Atualizando seus dados...";
            this.snackErro = true;
            //* Recarrega a tela, para apresentar os dados atualizados (pois se CEP já estava na base, vai apenas atualizar alguns campos)
            setTimeout(() => {
              this.$router.push((this.rota.includes("/assinaturas") ? "/assinaturas/" : "/administrativo/") + "cep/" + this.id);
              this.$router.go((this.rota.includes("/assinaturas") ? "/assinaturas/" : "/administrativo/") + "cep/" + this.id);
            }, 2000);
          } else {
            this.mensagem = "CEP já cadastrado! Atualizando Entrega e Roteiro...";
            this.snackErro = true;
          }
        } else {
          this.mensagem = "Dados foram atualizados";
          this.snackbar = true;
        }

        if (this.telaCliente) {
          setTimeout(
            () => {
              this.$emit("update:dialogCEP", false);
            },
            retorno.data.existe ? 3000 : 100
          );
        } else if (novo == "true") {
          this.$router.push((this.rota.includes("/assinaturas") ? "/assinaturas/" : "/administrativo/") + "cep");
          this.$router.go((this.rota.includes("/assinaturas") ? "/assinaturas/" : "/administrativo/") + "cep");
        } else if (novo == "retornar") {
          this.retornar();
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    async carregarUFs() {
      this.ufs = [];
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/uf/lista");
        this.ufs = response.data;
        if (this.$store.state.usuario.site_pais != "BR") {
          this.ufs.forEach((element) => {
            element.nome += "/" + element.pais;
          });
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de " + (this.$store.state.usuario.site_pais == "BR" ? "UFs" : "distritos");
        this.snackErro = true;
      }
    },
    async carregarMunicipios() {
      this.municipios = [];
      if (this.idUF) {
        try {
          //* Carrega os municípios da UF
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/municipio/lista?idUF=" + this.idUF);
          this.municipios = response.data;
        } catch {
          this.isUpdating = true;
          this.mensagem = "Ocorreu um erro ao carregar a lista de " + (this.$store.state.usuario.site_pais == "BR" ? "municípios da UF" : "concelhos do distrito");
          this.snackErro = true;
        }
      }
    },
    async carregarTiposLogradouros() {
      this.tipos = [];
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/tipo-logradouro/lista");
        this.tipos = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de tipos de logradouros";
        this.snackErro = true;
      }
    },
    async carregarEntregadores(origem) {
      try {
        //* Busca entregadores ativos, pois se está inativo, não deveria estar associado a um CEP
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/entregador/lista" + (origem == "VA" ? "?vendaAvulsa=1" : ""));
        if (origem == "ASS") {
          this.entregadores = response.data;
        } else {
          this.entregadoresVA = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de Entregadores";
        this.snackErro = true;
      }
    },

    async carregarRotas(origem) {
      try {
        //* Busca rotas ativas, pois se está inativa, não deveria estar associada a um CEP
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/rota/lista" + (origem == "VA" ? "?vendaAvulsa=1" : ""));
        if (origem == "ASS") {
          this.rotas = response.data;
          this.rotas.forEach((element) => {
            element.nome = element.nome + " - " + element.municipio + "/" + element.uf;
          });
        } else {
          this.rotasVA = response.data;
          this.rotasVA.forEach((element) => {
            element.nome = element.nome + " - " + element.municipio + "/" + element.uf;
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de Rotas";
        this.snackErro = true;
      }
    },
    buscarCep() {
      if (this.cep.cep != null && this.cep.cep.length == 9) {
        this.idUF = null;
        this.cep.idMunicipio = null;
        this.cep.bairro = "";
        this.cep.idTipo = null;
        this.cep.logradouro = "";

        //* Busca o cep no webservice gratuito, tem que ser axios, senão dá erro crossorigin
        axios
          .get("https://viacep.com.br/ws/" + this.cep.cep + "/json/")
          .then((response) => {
            var cep = response.data;
            if (cep.erro) {
              this.mensagem = "CEP não localizado";
              this.snackErro = true;
              this.atualizarRoteiro();
            } else {
              //* Busca o município pelo código IBGE
              api
                .get(process.env.VUE_APP_URL_WEB_API + "adm/municipio/ibge/" + cep.ibge)
                .then((response) => {
                  var municipio = response.data[0];
                  if (municipio != null && municipio != undefined) {
                    this.idUF = municipio.idUF;
                    this.carregarMunicipios(municipio.idUF);
                    this.cep.idMunicipio = municipio.id;
                    this.cep.bairro = cep.bairro;

                    var tipoLogr = cep.logradouro.split(" ")[0];
                    var itemTipo = this.tipos.find((tipo) => tipo.nome == tipoLogr);
                    if (!itemTipo) {
                      if (tipoLogr.startsWith("S") || tipoLogr.startsWith("Q")) {
                        itemTipo = this.tipos.find((tipo) => tipo.nome == "Setor");
                      } else {
                        itemTipo = this.tipos.find((tipo) => tipo.nome == "Rua");
                      }
                      this.cep.logradouro = cep.logradouro;
                    } else {
                      this.cep.logradouro = cep.logradouro.substring(tipoLogr.length + 1);
                    }
                    this.cep.idTipo = itemTipo.id;
                  }
                  this.atualizarRoteiro();
                })
                .catch(() => {
                  this.isUpdating = true;
                  this.mensagem = "Ocorreu um erro ao carregar a lista de " + (this.$store.state.usuario.site_pais == "BR" ? "municípios da UF" : "concelhos do distrito");
                  this.snackErro = true;
                  this.atualizarRoteiro();
                });
            }
          })
          .catch(() => {
            this.isUpdating = true;
            this.mensagem = "Ocorreu um erro ao buscar os dados do cep";
            this.snackErro = true;
            this.atualizarRoteiro();
          });
      }
    },
    async atualizarRoteiro() {
      this.validacao.idMunicipio = false;
      this.validacao.bairro = false;
      this.validacao.idTipo = false;
      this.validacao.logradouro = false;

      if (this.cep.idMunicipio && this.cep.bairro && this.cep.idTipo && this.cep.logradouro) {
        try {
          //* Atualiza o entregador e a rota
          const response = await api.get(
            process.env.VUE_APP_URL_WEB_API +
              "adm/cep/rota?idMunicipio=" +
              this.cep.idMunicipio +
              "&bairro=" +
              this.cep.bairro +
              "&idTipo=" +
              this.cep.idTipo +
              "&logradouro=" +
              this.cep.logradouro
          );
          this.cep.idEntregador = response.data.idEntregador;
          this.cep.idRota = response.data.idRota;
        } catch {
          this.cep.idEntregador = null;
          this.cep.idRota = null;
        }

        try {
          //* Atualiza o entregador e a rota da venda avulsa
          const response = await api.get(
            process.env.VUE_APP_URL_WEB_API +
              "adm/cep/rotaVA?idMunicipio=" +
              this.cep.idMunicipio +
              "&bairro=" +
              this.cep.bairro +
              "&idTipo=" +
              this.cep.idTipo +
              "&logradouro=" +
              this.cep.logradouro
          );
          this.cep.idEntregadorVA = response.data.idEntregadorVA;
          this.cep.idRotaVA = response.data.idRotaVA;
        } catch {
          this.cep.idEntregadorVA = null;
          this.cep.idRotaVA = null;
        }
      } else {
        this.cep.idEntregador = null;
        this.cep.idRota = null;
        this.cep.idEntregadorVA = null;
        this.cep.idRotaVA = null;
      }
    },
    retornar() {
      if (this.telaCliente) {
        this.$emit("update:dialogCEP", false);
      } else {
        this.$router.push((this.rota.includes("/assinaturas") ? "/assinaturas/" : "/administrativo/") + "ceps");
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    filtrarUFs(item, queryText, itemText) {
      return util.filtrarUFs(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    }
  },
  async created() {
    this.carregarItem();
  },
  computed: {
    rota() {
      return this.$route.path;
    },
    moduloAssinaturas() {
      return this.$store.state.usuario.site_assinaturas;
    },
    idCEP() {
      return this.id || this.$route.params.id;
    }
  },
  watch: {
    id() {
      this.cep = {
        id: "",
        idMunicipio: null,
        cep: "",
        bairro: "",
        idTipo: null,
        logradouro: "",
        faixa: "",
        nroMinimo: 0,
        nroMaximo: 99999,
        idEntregador: null,
        idRota: null,
        entrega: this.moduloAssinaturas ? 1 : 0,
        roteiro: 0,
        idEntregadorVA: null,
        idRotaVA: null,
        situacao: 1
      };
      this.carregarItem();
    },
    idUF() {
      this.carregarMunicipios();
    }
  }
};
</script>
