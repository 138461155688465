<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">assignment</v-icon>
          <v-toolbar-title style="color: white" class="font-weight-light">Assinaturas</v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link to="/assinaturas/assinatura" style="text-decoration: none" v-if="direitoCriar">
            <v-btn color="success" fab small>
              <v-icon color="white" title="Incluir">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex md1 xs3>
                <v-dialog v-model="dialogFiltroAvancado" max-width="900px">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="blue" fab small :disabled="loading || loadingExp || loadingImp || loadingCart">
                      <v-icon color="white" title="Filtro Avançado">search</v-icon>
                    </v-btn>
                    <v-btn
                      color="red lighten-2"
                      style="color: white"
                      class="btn-rsn ml-n2 mb-6"
                      id="btn-limpa-filtro"
                      fab
                      @click="filtroAvancadoLimpar"
                      :disabled="loading || loadingExp || loadingImp || loadingCart"
                    >
                      <v-icon class="icon-rsn" small center title="Limpar Filtro">clear</v-icon>
                    </v-btn>
                  </template>

                  <template>
                    <v-card>
                      <v-tabs v-model="tab" background-color="blue accent-4" center-active dark>
                        <v-tab>Assinatura</v-tab>
                        <v-tab>Cliente</v-tab>
                        <v-tab>Parcela</v-tab>
                        <v-tab>Nota Fiscal</v-tab>
                        <v-tab>Pesquisas</v-tab>
                      </v-tabs>

                      <v-tabs-items v-model="tab">
                        <v-tab-item>
                          <FiltroAssinatura ref="filtroAssinatura" />
                        </v-tab-item>
                        <v-tab-item>
                          <FiltroCliente ref="filtroCliente" prefixo="c." />
                        </v-tab-item>
                        <v-tab-item>
                          <FiltroParcela ref="filtroParcela" prefixo="p." origem="ASS" />
                        </v-tab-item>
                        <v-tab-item>
                          <FiltroNF ref="filtroNF" prefixo="n." v-bind:nfe="true" v-bind:nfsc="true" v-bind:nfse="true" />
                        </v-tab-item>
                        <v-tab-item>
                          <Pesquisas ref="pesquisas" prefixo="a" />
                        </v-tab-item>
                      </v-tabs-items>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="orange lighten-2 white" style="color: white" class="btn-rsn" @click="filtroAvancadoRetornar">
                          <v-icon class="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
                        </v-btn>
                        <v-btn outlined color="red white" style="color: white" class="btn-rsn" @click="filtroAvancadoLimpar">
                          <v-icon class="icon-rsn" left>clear</v-icon>Limpar
                        </v-btn>
                        <v-btn v-if="tab != 4" color="blue white" style="color: white" class="btn-rsn" depressed @click="limpaFiltro">
                          <v-icon class="icon-rsn" left>tune</v-icon>Filtrar
                        </v-btn>
                        <v-btn v-if="direitoExportar" outlined color="blue white" style="color: white" class="btn-rsn" depressed @click="filtroAvancadoFiltrar(true, false)">
                          <v-icon class="icon-rsn" left>description</v-icon>Exportar
                        </v-btn>
                        <v-btn
                          v-if="tab != 4 && direitoImprimir"
                          outlined
                          color="blue white"
                          style="color: white"
                          class="btn-rsn"
                          depressed
                          @click="filtroAvancadoFiltrar(false, true)"
                        >
                          <v-icon class="icon-rsn" left>print</v-icon>Imprimir
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex md1 xs2>
                <v-btn
                  v-if="direitoExportar"
                  color="blue"
                  :loading="loadingExp"
                  :disabled="loading || loadingExp || loadingImp || loadingCart"
                  fab
                  small
                  @click="filtroAvancadoFiltrar(true, false)"
                >
                  <v-icon color="white" title="Exportar">description</v-icon>
                </v-btn>
              </v-flex>
              <v-flex md1 xs2>
                <v-btn
                  v-if="direitoImprimir"
                  color="blue"
                  :loading="loadingImp"
                  :disabled="loading || loadingExp || loadingImp || loadingCart"
                  fab
                  small
                  @click="filtroAvancadoFiltrar(false, true)"
                >
                  <v-icon color="white" title="Imprimir">print</v-icon>
                </v-btn>
              </v-flex>

              <v-flex md1 xs2>
                <v-menu v-model="mnuCarteira" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-btn v-if="direitoCarteira" v-on="on" color="blue" fab small :loading="loadingCart" :disabled="loading || loadingExp || loadingImp || loadingCart">
                      <v-icon color="white" title="Carteira" style="position: relative">dns</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="dtCarteira"
                    locale="pt-br"
                    no-title
                    @input="
                      mnuCarteira = false;
                      relCarteira();
                    "
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs6 md1>
                <v-text-field
                  background-color="white"
                  class="mb-2 elevation-0"
                  v-model="idAssinaturaPesquisa"
                  v-mask="'#######'"
                  append-icon="search"
                  label="Assinatura"
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                  :disabled="loading || loadingExp || loadingImp || loadingCart"
                  hint="ID da Assinatura"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 md1>
                <v-text-field
                  background-color="white"
                  class="mb-2 elevation-0"
                  v-model="idRenovacaoPesquisa"
                  v-mask="'#######'"
                  append-icon="search"
                  label="Renovação"
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                  :disabled="loading || loadingExp || loadingImp || loadingCart"
                  hint="Se Assinatura preenchido: Número da Renovação, senão: ID da Renovação"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 md2>
                <v-text-field
                  background-color="white"
                  class="mb-2 elevation-0"
                  v-model="identificacaoPesquisa"
                  v-mask="'##############'"
                  append-icon="search"
                  label="Identificação"
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                  :disabled="loading || loadingExp || loadingImp || loadingCart"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md2>
                <v-text-field
                  background-color="white"
                  class="mb-2 elevation-0"
                  v-model="nomePesquisa"
                  append-icon="search"
                  label="Nome"
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                  :disabled="loading || loadingExp || loadingImp || loadingCart"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
            :loading="loading"
            loading-text="Carregando..."
          >
            <template v-slot:item.actions="{ item }">
              <v-icon v-if="direitoEditar && item.id" color="blue" title="Editar" @click="editarAssinatura(item)">edit</v-icon>
              <v-icon v-if="direitoEditar && item.idRenovacao" color="blue lighten-2" title="Editar Renovação Atual" @click="editarRenovacao(item)">edit</v-icon>
              <v-icon v-if="direitoEditar" color="blue" title="Visualizar Log" @click="visualizarLog(item)">info</v-icon>
              <v-icon v-if="direitoAssinante && item.idCliente" color="orange" title="Editar Cliente" @click="editarAssinante(item)">account_circle</v-icon>
              <v-icon v-if="direitoParcelas" color="blue lighten-2" title="Abrir no Financeiro" @click="visualizarParcelas(item)">add_circle_outline</v-icon>
            </template>

            <template v-slot:item.status="{ item }">
              <span
                :class="
                  item.status == 'CORRENTE'
                    ? 'blue--text'
                    : item.status == 'SUSPENSA' || item.status == 'CORTADA'
                    ? 'orange--text'
                    : item.status == 'CANCELADA' || item.status == 'RECUPERAÇÃO' || item.status == 'EXPIRADA'
                    ? 'red--text'
                    : item.status == 'PROPOSTA'
                    ? 'green--text'
                    : item.status == 'EXCLUÍDA'
                    ? 'red--text'
                    : ''
                "
                v-html="item.status == 'EXCLUÍDA' ? '<b>EXCLUÍDA</b' : item.status"
              ></span>
            </template>

            <template v-slot:item.vlrLiquido="{ item }">
              {{ formatCurrency(item.vlrLiquido) }}
            </template>

            <template v-slot:item.dtCriacao="{ item }">
              {{ formatDateTime(item.dtCriacao) }}
            </template>

            <template v-slot:item.dtInicio="{ item }">
              {{ formatDate(item.dtInicio) }}
            </template>

            <template v-slot:item.dtFim="{ item }">
              {{ formatDate(item.dtFim) }}
            </template>

            <template v-slot:item.exemplares="{ item }">
              <span v-html="`${item.exemplares} | ${item.ativos} | ${item.entregues} | ${formatCurrency(item.vlrLiquido / item.ativos)}`"></span
            ></template>

            <template v-slot:item.renovacao="{ item }">
              <span v-html="item.renovacao + ` '${item.codAntigo || item.idRenovacao}'`"></span>
            </template>

            <template v-slot:item.online="{ item }">
              <span v-html="item.online == 1 ? 'Sim' : ''"></span>
            </template>

            <template v-slot:item.renovacaoAutomatica="{ item }">
              <span v-html="item.renovacaoAutomatica == 2 ? 'Sim' : ''"></span>
            </template>

            <template v-slot:item.actions2="{ item }">
              <v-icon v-if="item.id" color="blue" title="Recibo do Contrato" @click="reciboContrato(item)">print</v-icon>
            </template>
          </v-data-table>
          <div align="right">
            <v-btn-toggle v-model="calcular" group>
              <v-btn x-small text value="sim" @click="calcularTotal"> Calcular </v-btn>
            </v-btn-toggle>
          </div>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 15" circle></v-pagination>
          </div>
        </div>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagemPesquisa }}</v-snackbar>

        <v-dialog v-model="dialogLogAlteracao" max-width="1200px">
          <v-card>
            <LogAlteracao :idAssinatura="idAssinatura" :dialogLogAlteracao.sync="dialogLogAlteracao" />
          </v-card>
        </v-dialog>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import FiltroAssinatura from "@/views/components/FiltroAssinatura.vue";
import FiltroCliente from "@/views/components/FiltroCliente.vue";
import FiltroParcela from "@/views/components/FiltroParcela.vue";
import FiltroNF from "@/views/components/FiltroNF.vue";
import Pesquisas from "@/views/components/Pesquisas.vue";
import LogAlteracao from "@/views/components/LogAlteracao.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  components: {
    FiltroAssinatura,
    FiltroCliente,
    FiltroParcela,
    FiltroNF,
    Pesquisas,
    LogAlteracao
  },

  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "160" },
        { text: "ID", value: "id" },
        { text: "Cliente", value: "cliente" },
        { text: "Plano", value: "plano" },
        { text: "Produtos", value: "produtos" },
        { text: "Tipos", value: "tipos" },
        { text: "Modalidade", value: "modalidade" },
        { text: "Cobrado", value: "vlrLiquido", align: "end" },
        { text: "Status", value: "status" },
        { text: "Origem", value: "origemVenda" },
        { text: "Data de Criação", value: "dtCriacao", divider: "true" },
        { text: "Renovação", value: "renovacao" },
        { text: "Auto", value: "renovacaoAutomatica" },
        { text: "Início", value: "dtInicio" },
        { text: "Fim", value: "dtFim", divider: "true" },
        { text: "Exemplares", value: "exemplares", width: "140" },
        { text: "Online", value: "online", divider: "true" },
        { text: "", value: "actions2" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      inicial: true,
      filtroAssinatura: null,
      dialogFiltroAvancado: false,
      direitoCriar: false,
      direitoEditar: false,
      direitoAssinante: false,
      direitoParcelas: false,
      direitoCarteira: false,
      direitoExportar: false,
      direitoImprimir: false,

      dtCarteira: null,
      mnuCarteira: false,

      mensagemPesquisa: "",
      snackErro: false,

      idAssinaturaPesquisa: null,
      idRenovacaoPesquisa: null,
      identificacaoPesquisa: null,
      nomePesquisa: null,

      dialogLogAlteracao: false,
      idAssinatura: "",

      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      valorTotal: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "desc",
      options: {},
      idSitePrincipal: null,
      tab: null,
      loader: null,
      loading: false,
      loadingExp: false,
      loadingImp: false,
      loadingCart: false,
      calcular: "nao"
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    editarAssinatura(item) {
      if (this.direitoEditar) {
        window.open("/assinaturas/assinatura/" + item.id, "_blank");
      }
    },
    editarRenovacao(item) {
      if (this.direitoEditar) {
        window.open("/assinaturas/assinatura/" + item.id + "/" + item.idRenovacao, "_blank");
      }
    },
    editarAssinante(item) {
      if (this.direitoAssinante) {
        window.open("/assinaturas/assinante/" + item.idCliente, "_blank");
      }
    },
    visualizarParcelas(item) {
      window.open("/financeiro/parcelas/receber?idAssinatura=" + item.id, "_blank");
    },
    filtroAvancadoRetornar() {
      //* Esconde a dialog do filtro avançado
      this.dialogFiltroAvancado = false;
    },
    filtroAvancadoLimpar() {
      //* Limpa os registros da tabela
      this.registros = [];
      this.totalRegistros = 0;
      this.valorTotal = 0;
      this.totalPaginas = 0;
      this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS ASSINATURAS...";

      //* Limpa os campos dos filtros
      if (this.$refs.filtroCliente) {
        this.$refs.filtroCliente.limpar();
      }
      if (this.$refs.filtroAssinatura) {
        this.$refs.filtroAssinatura.limpar();
      }
      if (this.$refs.filtroParcela) {
        this.$refs.filtroParcela.limpar();
      }
      if (this.$refs.filtroNF) {
        this.$refs.filtroNF.limpar();
      }
    },
    limpaFiltro() {
      var pgAnterior = this.paginaAtual;
      this.paginaAtual = 1;
      if (pgAnterior == 1) {
        this.filtroAvancadoFiltrar(false, false);
      }
    },
    async reciboContrato(item) {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/assinatura/contrato/" + item.id + "?idSite=" + this.idSitePrincipal);
      util.imprimir(response.data);
    },
    async filtroAvancadoFiltrar(exportar, imprimir) {
      //* Limpa os registros da tabela
      this.registros = [];
      this.totalRegistros = 0;
      this.valorTotal = 0;
      this.totalPaginas = 0;

      if (this.idAssinaturaPesquisa && !this.idRenovacaoPesquisa) {
        this.$router.push("/assinaturas/assinatura/" + this.idAssinaturaPesquisa);
        return;
      }
      if (this.identificacaoPesquisa && !util.valida_cpf(this.identificacaoPesquisa) && !util.valida_cnpj(this.identificacaoPesquisa)) {
        this.mensagemPesquisa = "Identificação inválida";
        this.snackErro = true;
        return;
      }

      var l = null;
      if (exportar) {
        this.loader = "loadingExp";
        l = this.loader;
        this[l] = !this[l];
        setTimeout(() => (this[l] = false), 5000);
        this.loader = null;
      }
      if (imprimir) {
        this.loader = "loadingImp";
        l = this.loader;
        this[l] = !this[l];
      }

      if (!imprimir && !exportar) {
        this.carregando = true;
        this.mensagemPesquisa = "";
        this.loading = true;
      } else {
        this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS ASSINATURAS...";
      }

      //* Esconde a dialog do filtro avançado
      this.dialogFiltroAvancado = false;

      //* Filtros avançados da assinatura
      var filtroAssinaturaUrl = this.montaFiltro();
      if (!filtroAssinaturaUrl.includes("renovacoesAtual")) {
        filtroAssinaturaUrl += "&renovacoesAtual=ATUAL";
      }
      if (!filtroAssinaturaUrl.includes("idSite")) {
        filtroAssinaturaUrl += "&idSite=" + this.idSitePrincipal;
      }
      if (this.identificacaoPesquisa) {
        if (filtroAssinaturaUrl.includes("identificacao=")) {
          this.identificacaoPesquisa = "";
        } else {
          filtroAssinaturaUrl += "&c.identificacao=" + this.identificacaoPesquisa;
        }
      }
      if (this.idRenovacaoPesquisa) {
        if (this.idAssinaturaPesquisa) {
          //* Se tem idAssinatura, considera renovação como renovacao (nro)
          if (!filtroAssinaturaUrl.includes("idAssinatura=")) {
            filtroAssinaturaUrl += "&idAssinatura=" + this.idAssinaturaPesquisa;
          }
          if (!filtroAssinaturaUrl.includes("renovacoesRenovacao=")) {
            filtroAssinaturaUrl += "&renovacoesRenovacao=" + this.idRenovacaoPesquisa;
          }
        } else {
          //* Se não tem idAssinatura, considera renovação como id
          filtroAssinaturaUrl += "&idRenovacao=" + this.idRenovacaoPesquisa;
        }
      }
      if (this.nomePesquisa) {
        if (filtroAssinaturaUrl.includes("&c.nome=")) {
          this.nomePesquisa = "";
        } else {
          filtroAssinaturaUrl += "&c.nome=" + this.nomePesquisa;
          filtroAssinaturaUrl += "&c.nomeFantasia=" + this.nomePesquisa;
        }
      }

      var camposAnuncio = "";
      if (exportar) {
        if (this.$refs.pesquisas) {
          //* Carrega os filtros da nota fiscal
          camposAnuncio = this.$refs.pesquisas.filtroUrlCampos();
        }
      }

      try {
        //* Consulta os dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "ass/assinatura?" +
            filtroAssinaturaUrl +
            "&campos=" +
            camposAnuncio +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            // "&filtro=" +
            // this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem +
            (exportar ? "&exportar=1" : "") +
            (imprimir ? "&imprimir=1" : "") +
            (this.calcular == "sim" ? "&calcular=1" : "")
        );

        if (imprimir) {
          util.imprimir(response.data);
        } else if (!exportar) {
          //* Carrega os dados de retorno
          this.registros = response.data.data;
          this.registros.forEach((element) => {
            if (element.lgpd) {
              element.cliente = util.abreviaNome(element.cliente);
            }
          });
          this.totalRegistros = response.data.total;
          this.valorTotal = response.data.valorTotal;
          this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
          this.rodape.pageText = this.totalRegistros + " itens" + (this.valorTotal ? " = " + util.formatCurrency(this.valorTotal, this.$store.state.usuario.site_pais, true) : "");

          //* Fecha a mensagem de carregando
          this.carregando = false;

          //* Registra caso algum item não seja encontrado
          this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
        }
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else if (e.response && e.response.data.length > 0) {
          this.mensagemPesquisa = "ERRO: " + e.response.data[0].mensagem;
          this.snackErro = true;
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
          this.snackErro = true;
        }
      } finally {
        this.loading = false;
        if (imprimir) {
          this[l] = false;
          this.loader = null;
        }
      }
    },
    montaFiltro() {
      var filtroAssinaturaUrl = "";
      //* Carrega os filtros da assinatura
      if (this.$refs.filtroAssinatura) {
        filtroAssinaturaUrl = this.$refs.filtroAssinatura.filtroUrlParametros(this.idSitePrincipal);
      }

      if (this.$refs.filtroCliente) {
        filtroAssinaturaUrl = filtroAssinaturaUrl + this.$refs.filtroCliente.filtroUrlParametros(this.idSitePrincipal);
      }

      if (this.$refs.filtroParcela) {
        filtroAssinaturaUrl = filtroAssinaturaUrl + this.$refs.filtroParcela.filtroUrlParametros(this.idSitePrincipal);
      }

      if (this.$refs.filtroNF) {
        filtroAssinaturaUrl = filtroAssinaturaUrl + this.$refs.filtroNF.filtroUrlParametros();
      }
      return filtroAssinaturaUrl;
    },

    async calcularTotal() {
      //* Filtros avançados da assinatura
      var filtroAssinaturaUrl = this.montaFiltro();
      if (!filtroAssinaturaUrl.includes("renovacoesAtual")) {
        filtroAssinaturaUrl += "&renovacoesAtual=ATUAL";
      }
      if (!filtroAssinaturaUrl.includes("idSite")) {
        filtroAssinaturaUrl += "&idSite=" + this.idSitePrincipal;
      }

      //* Consulta os dados
      const response = await api.get(
        process.env.VUE_APP_URL_WEB_API + "ass/assinatura/total?" + filtroAssinaturaUrl + "&pagina=" + this.paginaAtual + "&tamanho=" + this.registrosPagina
      );

      //* Carrega os dados de retorno
      this.valorTotal = response.data.valorTotal;
      this.rodape.pageText = this.totalRegistros + " itens " + (this.valorTotal ? " = " + util.formatCurrency(this.valorTotal, this.$store.state.usuario.site_pais, true) : "");
    },

    closeFiltroAssinatura() {
      if (this.dialogFiltroAvancado) {
        this.dialogFiltroAvancado = false;
        this.filtroAvancadoFiltrar(false, false);
      }
    },

    async relCarteira() {
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      //* Limpa os registros da tabela
      this.registros = [];
      this.totalRegistros = 0;
      this.valorTotal = 0;
      this.totalPaginas = 0;

      this.loader = "loadingCart";
      var l = this.loader;
      this[l] = !this[l];

      api
        .get(process.env.VUE_APP_URL_WEB_API + "ass/assinatura/carteira?idSite=" + this.idSitePrincipal + "&data=" + this.dtCarteira)
        .then((response) => {
          setTimeout(() => {
            try {
              util.imprimir(response.data);
              this.carregando = false;
              this.mensagemPesquisa = "";
              this[l] = false;
              this.loader = null;
            } catch {
              this.mensagemPesquisa = "Ocorreu um erro ao carregar a carteira de assinaturas: Verifique se seu navegador está bloqueando pop-up's do SuitaSIM";
              this[l] = false;
              this.loader = null;
            }
          }, 500);
        })
        .catch((e) => {
          this.mensagemPesquisa = "Ocorreu um erro ao carregar a carteira de assinaturas: " + (e.response && e.response.data ? e.response.data[0] : e);
          this[l] = false;
          this.loader = null;
        });
    },

    visualizarLog(item) {
      this.idAssinatura = item.id + "";
      this.dialogLogAlteracao = true;
    },
    closeLogAlteracao() {
      this.dialogLogAlteracao = false;
      this.idAssinatura = "";
    },

    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_ASSINATURAS_ACESSO"]);
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach((element) => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_EDITAR") {
        this.direitoEditar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_CARTEIRA") {
        this.direitoCarteira = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_EXPORTAR") {
        this.direitoExportar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_IMPRIMIR") {
        this.direitoImprimir = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_EDITAR") {
        this.direitoAssinante = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ACESSO") {
        this.direitoParcelas = true;
      }
    });

    if (this.$store.state.usuario.site_protheus) {
      this.headers.push({ text: "Protheus", value: "idProtheus" });
    }
  },

  watch: {
    dtCarteira() {
      this.mnuCarteira = false;
    },
    dialogFiltroAvancado(val) {
      var retorno = JSON.parse(JSON.stringify(val));
      if (retorno.status != null) {
        this.filtroAssinatura = retorno.assinatura;
        retorno = retorno.status;
      } else {
        retorno = val;
      }
      retorno || this.closeFiltroAssinatura();
    },
    paginaAtual: function () {
      this.filtroAvancadoFiltrar(false, false);
    },
    dialogLogAlteracao(val) {
      val || this.closeLogAlteracao();
    },

    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          if (this.options.sortBy[0] == "id") {
            this.ordenacao = "assinatura.id";
          } else if (this.options.sortBy[0] == "cliente") {
            this.ordenacao = "operacao.nome";
          } else if (this.options.sortBy[0] == "plano") {
            this.ordenacao = "plano.nome";
          } else if (this.options.sortBy[0] == "status") {
            this.ordenacao = "assinatura.status";
          } else if (this.options.sortBy[0] == "dtCriacao") {
            this.ordenacao = "assinatura.dtCriacao";
          } else if (this.options.sortBy[0] == "dtInicio") {
            this.ordenacao = "renovacoes.dtInicio";
          } else if (this.options.sortBy[0] == "dtFim") {
            this.ordenacao = "renovacoes.dtFim";
          } else if (this.options.sortBy[0] == "online") {
            this.ordenacao = "assinatura.online";
            // } else if (this.options.sortBy[0] == "rede") {
            //   this.ordenacao = "assinatura.rede";
          } else {
            this.ordenacao = this.options.sortBy[0];
          }
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        if (!this.inicial) {
          this.filtroAvancadoFiltrar(false, false);
        } else {
          this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS ASSINATURAS...";
          this.inicial = false;
        }
      }
    }
  }
};
</script>
