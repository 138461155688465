<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">account_balance</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">Cedente</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="cedente.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              clearable
              no-data-text
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              autofocus
              v-model="cedente.nome"
              label="Nome"
              maxlength="100"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                (value) => !!value || 'Obrigatório.',
                (value) => valida_nome(value) || 'Nome inválido',
                (value) => value.length > 2 || 'Preencha o nome do cedente'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                cedente.nome = pLetra(cedente.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              v-model="cedente.forma"
              :disabled="isUpdating"
              :items="formas"
              :filter="filtrarItens"
              hint="Obrigatório"
              :rules="[validacao.forma == false || validacao.forma, (value) => !!value || 'Obrigatório.']"
              label="Selecione o Tipo de Cedente"
              :name="Math.random()"
              @change="
                carregarContasExtrato();
                carregarFormasPgto();
                validacao.forma = false;
              "
              no-data-text
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              v-model="bancoSelecionado"
              :disabled="isUpdating"
              :items="bancos"
              :filter="filtrarObjetos"
              label="Selecione o Banco"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idBanco == false || validacao.idBanco, (value) => !!value.id || 'Obrigatório.']"
              no-data-text
              @change="
                cedente.padraoNomeRemessa = bancoSelecionado.numero == '748' ? 'CCCCCMDD' : '';
                validacao.idBanco = false;
              "
              return-object
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md2 v-if="cedente.forma != 'CELESC'">
            <v-text-field
              v-model="cedente.agencia"
              :label="`Agência${cedente.forma == 'BOLETO' && bancoSelecionado.numero == '748' ? '.Posto' : ''}`"
              :maxlength="cedente.forma == 'BOLETO' && bancoSelecionado.numero == '748' ? 7 : cedente.forma == 'EXTRATO' ? 5 : 4"
              :rules="[validacao.agencia == false || validacao.agencia, (value) => !value || value.length > 3 || 'Agência inválida']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.agencia = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md4 v-if="cedente.forma != 'CELESC'">
            <v-text-field
              v-model="cedente.contaCorrente"
              label="Conta Corrente"
              maxlength="20"
              :rules="[validacao.contaCorrente == false || validacao.contaCorrente]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.contaCorrente = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6 v-if="cedente.forma == 'BOLETO' && bancoSelecionado.numero == '041'">
            <v-text-field
              v-model="cedente.codConvenio"
              label="Cód. Convênio"
              maxlength="40"
              :rules="[validacao.codConvenio == false || validacao.codConvenio]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.codConvenio = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md2>
            <v-text-field
              v-model="cedente.codCedente"
              :disabled="isUpdating"
              label="Cód. Cedente"
              maxlength="40"
              :rules="[validacao.codCedente == false || validacao.codCedente, (value) => (value && value.length > 2) || 'Obrigatório.']"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.codCedente = false"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md2 v-if="cedente.forma == 'BOLETO'">
            <v-text-field
              v-model="cedente.carteira"
              :disabled="isUpdating"
              label="Carteira"
              maxlength="3"
              :rules="[validacao.carteira == false || validacao.carteira]"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.carteira = false"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md2 v-if="cedente.forma == 'BOLETO'">
            <v-switch
              v-model="cedente.nomeFantasia"
              :disabled="isUpdating"
              :label="`${cedente.nomeFantasia == 1 ? 'Nome Fantasia no Boleto' : 'Razão Social no Boleto'}`"
            ></v-switch>
          </v-flex>
          <v-flex xs12></v-flex>

          <v-flex xs6 md2 v-if="cedente.forma == 'EXTRATO'">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="cedente.taxaCobrancaBoleto"
              label="Taxa Cobrança Boleto"
              :allowNegative="false"
              :max="9"
              :rules="[validacao.taxaCobrancaBoleto == false || validacao.taxaCobrancaBoleto]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.taxaCobrancaBoleto = false"
            ></v-currency-field>
          </v-flex>
          <v-flex xs6 md2 v-if="cedente.forma == 'EXTRATO'">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="cedente.taxaRegistro"
              label="Taxa Registro Boleto"
              :allowNegative="false"
              :max="9"
              :rules="[validacao.taxaRegistro == false || validacao.taxaRegistro]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.taxaRegistro = false"
            ></v-currency-field>
          </v-flex>
          <v-flex xs6 md2 v-if="cedente.forma == 'EXTRATO'">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="cedente.taxaCobrancaDC"
              label="Taxa Cobrança Débito Conta"
              :allowNegative="false"
              :max="9"
              :rules="[validacao.taxaCobrancaDC == false || validacao.taxaCobrancaDC]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.taxaCobrancaDC = false"
            ></v-currency-field>
          </v-flex>
          <v-flex xs6 md2 v-if="cedente.forma == 'EXTRATO'">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="cedente.taxaTransacao"
              label="Taxa Transação Débito Conta"
              :allowNegative="false"
              :max="9"
              :rules="[validacao.taxaTransacao == false || validacao.taxaTransacao]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.taxaTransacao = false"
            ></v-currency-field>
          </v-flex>
          <v-flex xs6 md2 v-if="cedente.forma == 'EXTRATO'">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="cedente.diasExtrato"
              label="Dias Crédito Extrato"
              :decimalLength="0"
              :allowNegative="false"
              :max="9"
              :rules="cedente.forma != 'EXTRATO' ? [] : [validacao.diasExtrato == false || validacao.diasExtrato, (value) => parseInt(value) > 0 || 'Obrigatório.']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.diasExtrato = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs12 md6 v-if="cedente.forma == 'BOLETO'">
            <v-autocomplete
              v-model="origens"
              :disabled="isUpdating"
              :items="itensOrigens"
              :filter="filtrarObjetos"
              label="Origens"
              item-text="nome"
              item-value="id"
              :rules="[validacao.origens == false || validacao.origens]"
              clearable
              multiple
              no-data-text
              @change="validacao.origens = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6 v-if="cedente.forma == 'BOLETO'">
            <v-autocomplete
              v-model="cedente.idFormaPgto"
              :disabled="isUpdating"
              :items="formasPgto"
              :filter="filtrarObjetos"
              label="Forma de Pagamento"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idFormaPgto == false || validacao.idFormaPgto]"
              clearable
              no-data-text
              @change="validacao.idFormaPgto = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-autocomplete
              v-model="cedente.idConta"
              :disabled="isUpdating"
              :items="contasMovimento"
              :filter="filtrarObjetos"
              label="Conta para Quitação"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idConta == false || validacao.idConta]"
              clearable
              no-data-text
              @change="validacao.idConta = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12 v-if="cedente.forma == 'EXTRATO'">
            <v-autocomplete
              v-model="cedente.contasAplicacao"
              :disabled="isUpdating"
              :items="contasAplicacao"
              :filter="filtrarObjetos"
              label="Contas de Aplicações Financeiras"
              item-text="nome"
              item-value="id"
              :rules="[validacao.contasAplicacao == false || validacao.contasAplicacao]"
              clearable
              no-data-text
              multiple
              @change="validacao.contasAplicacao = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6 v-if="cedente.forma == 'BOLETO'">
            <v-text-field
              v-model="cedente.ultimoNossoNumero"
              label="Último Nosso Número"
              v-mask="'###########'"
              :rules="cedente.forma != 'BOLETO' ? [] : [validacao.ultimoNossoNumero == false || validacao.ultimoNossoNumero, (value) => !!value || 'Obrigatório.']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.ultimoNossoNumero = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6 v-if="cedente.forma != 'CELESC' && cedente.forma != 'EXTRATO' && cedente.forma != 'ASAAS'">
            <v-text-field
              v-model="cedente.nroUltimaRemessa"
              label="Número Última Remessa"
              v-mask="'#######'"
              :rules="[validacao.nroUltimaRemessa == false || validacao.nroUltimaRemessa, (value) => parseInt(value) >= 0 || 'Obrigatório.']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.nroUltimaRemessa = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6 v-if="cedente.forma != 'CELESC' && cedente.forma != 'EXTRATO' && cedente.forma != 'ASAAS'">
            <v-text-field
              v-model="cedente.padraoNomeRemessa"
              label="Padrão Arquivo Remessa"
              maxlength="100"
              :rules="[validacao.padraoNomeRemessa == false || validacao.padraoNomeRemessa]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              :hint="
                cedente.padraoNomeRemessa == 'CCCCCMDD'
                  ? 'Sicredi: C=código do cedente, M=mês, DD=dia'
                  : 'Use: DD para dia, MM para mês, AAAA para ano, hh para hora, mm para minuto e ss para segundo'
              "
              persistent-hint
              @change="validacao.padraoNomeRemessa = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md3 v-if="cedente.forma == 'BOLETO'">
            <v-text-field
              v-model="cedente.tipoDocumento"
              label="Tipo do Documento"
              maxlength="3"
              :rules="cedente.forma != 'BOLETO' ? [] : [validacao.tipoDocumento == false || validacao.tipoDocumento, (value) => (value && value.length > 1) || 'Obrigatório']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="
                cedente.tipoDocumento = cedente.tipoDocumento ? cedente.tipoDocumento.toUpperCase() : '';
                validacao.tipoDocumento = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md3 v-if="cedente.forma == 'BOLETO' || cedente.forma == 'DEBITO_CONTA'">
            <v-autocomplete
              v-model="cedente.layout"
              :disabled="isUpdating"
              :items="layouts"
              :filter="filtrarItens"
              label="Layout Remessa/Retorno"
              :rules="
                cedente.forma != 'BOLETO' && cedente.forma != 'DEBITO_CONTA'
                  ? []
                  : [validacao.layout == false || validacao.layout, (value) => (value && value.length > 2) || 'Layout Remessa inválido']
              "
              no-data-text
              @change="validacao.layout = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs6 md3 v-if="cedente.forma != 'CELESC' && cedente.forma != 'EXTRATO' && cedente.forma != 'ASAAS'">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="cedente.vlrOutrosAcrescimos"
              label="Valor Taxa"
              :allowNegative="false"
              :max="99"
              :rules="[validacao.vlrOutrosAcrescimos == false || validacao.vlrOutrosAcrescimos]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.vlrOutrosAcrescimos = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3 v-if="cedente.forma == 'BOLETO'">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="cedente.percentualMulta"
              label="Percentual Multa"
              :allowNegative="false"
              :max="99"
              :rules="[validacao.percentualMulta == false || validacao.percentualMulta]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.percentualMulta = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3 v-if="cedente.forma == 'BOLETO'">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="cedente.percentualJurosDia"
              label="Percentual Juros Diário"
              :allowNegative="false"
              :max="99"
              :rules="[validacao.percentualJurosDia == false || validacao.percentualJurosDia]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.percentualJurosDia = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3 v-if="cedente.forma == 'BOLETO'">
            <v-text-field
              v-model="cedente.codMulta"
              label="Código da Multa"
              v-mask="'##'"
              :rules="[validacao.codMulta == false || validacao.codMulta]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.codMulta = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 v-if="cedente.forma == 'BOLETO'">
            <v-text-field
              v-model="cedente.localPagamento"
              label="Local de Pagamento"
              maxlength="100"
              :rules="[validacao.localPagamento == false || validacao.localPagamento]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.localPagamento = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 v-if="cedente.forma == 'BOLETO'">
            <v-text-field
              v-model="cedente.instrucoes"
              label="Instruções"
              maxlength="255"
              :rules="[validacao.instrucoes == false || validacao.instrucoes]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.instrucoes = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6 v-if="cedente.forma == 'BOLETO'">
            <v-text-field
              v-model="cedente.aceite"
              label="Aceite"
              maxlength="1"
              :rules="[validacao.aceite == false || validacao.aceite]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.aceite = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3 v-if="cedente.forma == 'BOLETO'">
            <v-text-field
              v-model="cedente.diasProtesto"
              label="Dias Protesto"
              v-mask="'##'"
              :rules="[validacao.diasProtesto == false || validacao.diasProtesto]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.diasProtesto = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3 v-if="cedente.forma == 'BOLETO'">
            <v-text-field
              v-model="cedente.diasBaixaAuto"
              label="Dias Baixa Auto"
              v-mask="'##'"
              :rules="[validacao.diasBaixaAuto == false || validacao.diasBaixaAuto]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.diasBaixaAuto = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6 v-if="cedente.forma == 'BOLETO'">
            <v-autocomplete
              v-model="cedente.formato"
              :items="formatos"
              :rules="[validacao.formato == false || validacao.formato, (value) => value != null || 'Obrigatório']"
              :filter="filtrarObjetos"
              label="Formato"
              item-text="nome"
              item-value="id"
              no-data-text
              :disabled="isUpdating"
              @change="validacao.formato = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6 v-if="cedente.forma == 'BOLETO'">
            <v-text-field
              v-model="cedente.avalista"
              label="Beneficiário"
              maxlength="100"
              :rules="[validacao.avalista == false || validacao.avalista]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.avalista = false"
              hint="Informar quando o Jornal é apenas o Avalista: [RAZÃO_SOCIAL CNPJ: CNPJformatado]"
              persistent-hint
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6 v-if="cedente.forma == 'BOLETO'">
            <v-text-field
              v-model="cedente.dadoConfig1"
              label="Dado Configuração 1"
              maxlength="20"
              :rules="[validacao.dadoConfig1 == false || validacao.dadoConfig1]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.dadoConfig1 = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 v-if="cedente.forma != 'BOLETO'"></v-flex>

          <v-flex xs12 md3 v-if="cedente.forma == 'BOLETO'">
            <v-switch v-model="cedente.emissaoBanco" :label="`${cedente.emissaoBanco == 1 ? 'Emissão pelo Banco' : 'Emissão pela Empresa'}`" :disabled="isUpdating"></v-switch>
          </v-flex>

          <v-flex xs12 md3 v-if="cedente.forma == 'DEBITO_CONTA'">
            <v-switch
              v-model="cedente.inclOptantes"
              :label="`${cedente.inclOptantes == 1 ? 'Gerar arquivo de Inclusão de Optantes' : 'Não gerar arquivo de inclusão de optantes'}`"
              :disabled="isUpdating"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md3 v-if="cedente.forma == 'BOLETO' || cedente.forma == 'DEBITO_CONTA'">
            <v-switch v-model="cedente.principal" :label="`${cedente.principal == 1 ? 'Principal' : 'Não é Principal'}`" :disabled="isUpdating"></v-switch>
          </v-flex>

          <v-flex xs12 md3 v-if="cedente.forma == 'BOLETO' || cedente.forma == 'DEBITO_CONTA'">
            <v-switch
              v-model="cedente.remessaPastaUnica"
              :label="`${cedente.remessaPastaUnica == 1 ? 'Remessa em Pasta Única' : 'Remessa em pasta individual'}`"
              :disabled="isUpdating"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md3 v-if="cedente.forma == 'BOLETO' || cedente.forma == 'DEBITO_CONTA'">
            <v-switch
              v-model="cedente.loteCancBoleto"
              :label="`${cedente.loteCancBoleto == 1 ? 'Gera Lote de Cancelamento' : 'Não gera lote de cancelamento'}`"
              :disabled="isUpdating || cedente.forma != 'BOLETO'"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md3 v-if="cedente.forma == 'BOLETO' || cedente.forma == 'DEBITO_CONTA'">
            <v-switch
              v-model="cedente.loteInclUnico"
              :disabled="isUpdating"
              :label="`${cedente.loteInclUnico == 1 ? 'Lote de Inclusão Diário Único' : 'Lote de Inclusão Individual'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md3 v-if="cedente.forma == 'BOLETO' || cedente.forma == 'DEBITO_CONTA'">
            <v-switch
              v-model="cedente.loteAltCancUnico"
              :disabled="isUpdating"
              :label="`${cedente.loteAltCancUnico == 1 ? 'Lote de Alteração/Cancelamento Diário Único' : 'Lote de Alteração/Cancelamento Individual'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md3 v-if="cedente.forma == 'DEBITO_CONTA'">
            <v-switch
              v-model="cedente.online"
              :label="`${cedente.online == 1 ? 'Cedente Disponível para Captação Online' : 'Cedente não diponível para captação online'}`"
            ></v-switch> </v-flex
          ><!--cedente.forma == 'BOLETO' || -->

          <v-flex xs12 md3 v-if="['BOLETO', 'DEBITO_CONTA'].includes(cedente.forma)">
            <v-switch
              v-model="cedente.quitarDataCredito"
              :disabled="isUpdating"
              :label="`${cedente.quitarDataCredito == 1 ? 'Quitar pela Data do Crédito' : 'Quitar pela Data de Pagamento'}`"
            ></v-switch>
          </v-flex>
          <v-flex xs12 md3>
            <v-switch v-model="cedente.situacao" :disabled="isUpdating" :label="`${cedente.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>

          <v-expansion-panels v-model="painel" focusable v-if="cedente.forma == 'EXTRATO'">
            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.contasHistoricos ? 'red--text' : ''">
                <strong>Contas dos Históricos</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogContaHistorico" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn :disabled="id == ''" color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formContaHistorico" v-model="contaHistoricoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Conta do Histórico</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeContaHistorico">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12>
                                    <v-autocomplete
                                      autofocus
                                      v-model="contaHistoricoSelecionada"
                                      :items="contasHistoricos"
                                      :rules="[(value) => (!!value && !!value.id) || 'Obrigatório.']"
                                      :filter="filtrarObjetos"
                                      label="Conta"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      return-object
                                    >
                                      <template v-slot:item="data">
                                        <v-list-item-content>
                                          <v-list-item-title v-html="data.item.nomeS"></v-list-item-title>
                                          <v-list-item-subtitle v-html="data.item.mae"></v-list-item-subtitle>
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-flex>

                                  <v-flex xs12 md4>
                                    <v-text-field
                                      v-model="contaHistoricoItem.codHistorico"
                                      label="Código do Histórico"
                                      maxlength="5"
                                      hint="Obrigatório"
                                      :rules="[(value) => !!value || 'Obrigatório.']"
                                      color="blue-grey lighten-2"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                    ></v-text-field>
                                  </v-flex>

                                  <v-flex xs12 md8>
                                    <v-text-field
                                      v-model="contaHistoricoItem.historico"
                                      label="Histórico"
                                      maxlength="25"
                                      color="blue-grey lighten-2"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                    ></v-text-field>
                                  </v-flex>

                                  <v-flex xs12 offset-md4 md8>
                                    <v-autocomplete
                                      v-model="contaHistoricoItem.acao"
                                      :items="acoes"
                                      :filter="filtrarItens"
                                      label="Ação"
                                      no-data-text
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      clearable
                                    >
                                    </v-autocomplete>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeContaHistorico">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveContaHistorico">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerContaHistorico"
                      :items="cedenteContasHistoricos"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:[getItemActions()]="{ item }">
                        <v-icon class="mr-2" color="blue lighten-2" title="Editar" @click="editarContaHistorico(item)">edit</v-icon>
                        <v-icon class="mr-2" color="red" title="Remover" @click="removerContaHistorico(item)">delete</v-icon>
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.contasHistoricos" :value="true" color="error" icon="priority_high" outlined>{{ validacao.contasHistoricos }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/financeiro/cedentes" id="btn-rsn" color="orange lighten-2 white" style="color: white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color: white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color: white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" id="btn-rsn" style="color: white" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      cedente: {
        id: "",
        idSite: null,
        idBanco: null,
        origens: null,
        idFormaPgto: null,
        idConta: null,
        nome: "",
        forma: null,
        nomeFantasia: 0,
        agencia: null,
        contaCorrente: null,
        codConvenio: null,
        codCedente: null,
        carteira: null,
        ultimoNossoNumero: null,
        nroUltimaRemessa: 0,
        padraoNomeRemessa: null,
        tipoDocumento: null,
        layout: null,
        vlrOutrosAcrescimos: 0,
        percentualMulta: 0,
        percentualJurosDia: 0,
        diasProtesto: 0,
        diasBaixaAuto: 0,
        instrucoes: null,
        aceite: null,
        inclOptantes: 0,
        localPagamento: null,
        codMulta: null,
        formato: 0,
        avalista: null,
        emissaoBanco: 0,
        dadoConfig1: null,
        remessaPastaUnica: 0,
        taxaCobrancaBoleto: 0,
        taxaCobrancaDC: 0,
        taxaRegistro: 0,
        taxaTransacao: 0,
        diasExtrato: 1,
        principal: 0,
        contasAplicacao: [],
        loteCancBoleto: 1,
        loteInclUnico: 0,
        loteAltCancUnico: 0,
        online: 0,
        quitarDataCredito: 0,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        idBanco: false,
        origens: false,
        idFormaPgto: false,
        idConta: false,
        nome: false,
        forma: false,
        nomeFantasia: false,
        agencia: false,
        contaCorrente: false,
        codConvenio: false,
        codCedente: false,
        carteira: false,
        ultimoNossoNumero: false,
        nroUltimaRemessa: false,
        padraoNomeRemessa: false,
        tipoDocumento: false,
        layout: false,
        vlrOutrosAcrescimos: false,
        percentualMulta: false,
        percentualJurosDia: false,
        diasProtesto: false,
        diasBaixaAuto: false,
        instrucoes: false,
        aceite: false,
        inclOptantes: false,
        localPagamento: false,
        codMulta: false,
        formato: false,
        avalista: false,
        emissaoBanco: false,
        dadoConfig1: false,
        remessaPastaUnica: false,
        taxaCobrancaBoleto: false,
        taxaCobrancaDC: false,
        taxaRegistro: false,
        taxaTransacao: false,
        diasExtrato: false,
        principal: false,
        loteCancBoleto: false,
        loteInclUnico: false,
        loteAltCancUnico: false,
        online: false,
        quitarDataCredito: false,
        situacao: false,
        contasAplicacao: false,
        contasHistoricos: false,
        mensagemErro: false
      },

      idSitePrincipal: null,
      bancoSelecionado: {},
      bancos: [],
      cedenteContasHistoricos: [],
      siteGrupo: [],
      layouts: [],

      origens: [],
      itensOrigens: [
        { id: "FIN", nome: "Financeiro" },
        { id: "ANU", nome: "Anúncio" },
        { id: "ASS", nome: "Assinatura" },
        { id: "PAC", nome: "Pacote" },
        { id: "AVU", nome: "Venda Avulsa" }
      ],
      formasPgto: [],

      contasMovimento: [],
      contasAplicacao: [],
      contasHistoricos: [],
      acoes: ["CREDITO_BOLETO", "TAXA_COBRANCA_BOLETO", "TAXA_REGISTRO_BOLETO", "CREDITO_DEBITO_CONTA", "TAXA_COBRANCA_DEBITO_CONTA", "CREDITO_CARTAO", "LANÇAMENTO"],

      contaHistoricoSelecionada: {},
      dialogContaHistorico: false,
      headerContaHistorico: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Conta", value: "nome" },
        { text: "Histórico", value: "historicoC" },
        { text: "Ação", value: "acao" }
      ],
      contaHistoricoValid: true,
      contaHistoricoIndex: -1,
      contaHistoricoItem: {
        id: null,
        idConta: null,
        codHistorico: "",
        historico: "",
        historicoC: "",
        acao: ""
      },
      defaultContaHistoricoItem: {
        id: null,
        idConta: null,
        codHistorico: "",
        historico: "",
        historicoC: "",
        acao: ""
      },

      painel: [0],
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false,
      bloqueiaBotoes: false,

      formas: ["BOLETO", "DEBITO_CONTA", "CELESC", "EXTRATO", "BRR", "ASAAS"], //, "CELESC_OLD", "SAMAE"

      formatos: [
        { id: 0, nome: "Padrão" },
        { id: 1, nome: "Carnê" },
        { id: 2, nome: "Comprovante Entrega" },
        { id: 3, nome: "Envelope" }
      ]
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      // if (this.cedente.layout == "DEBITOSIACC") {
      //   var erro = false;
      //   if (this.cedente.agencia.length != 4) {
      //     erro = true;
      //     this.validacao.agencia = "Agência deve ter 4 dígitos";
      //   }
      //   if (this.cedente.contaCorrente.length != 12) {
      //     erro = true;
      //     this.validacao.contaCorrente = "Conta Corrente deve ter 12 dígitos";
      //   }
      //   if (erro) {
      //     this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
      //     this.snackErro = true;
      //     return;
      //   }
      // }
      if (this.bancoSelecionado && this.bancoSelecionado.numero == "748") {
        this.cedente.padraoNomeRemessa = "CCCCCMDD";
      }

      this.cedente.idBanco = this.bancoSelecionado.id;

      if (this.cedente.forma == "BOLETO" && this.origens && this.origens.length) {
        this.cedente.origens = this.origens.join(",");
      } else {
        this.cedente.origens = null;
      }

      if (this.cedente.forma == "DEBITO_CONTA") {
        this.cedente.loteCancBoleto = 1;
      }

      this.cedente.itens = [];
      this.cedente.contasAplicacao.forEach((element) => {
        var item = {
          idConta: element
        };
        this.cedente.itens.push(item);
      });
      this.cedente.historicos = [];
      this.cedenteContasHistoricos.forEach((element) => {
        var item = {
          idConta: element.idConta,
          codHistorico: element.codHistorico,
          historico: element.historico,
          acao: element.acao
        };
        this.cedente.historicos.push(item);
      });

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/cedente/" + this.id, this.cedente);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "fin/cedente?idSite=" + this.idSitePrincipal, this.cedente);
          this.id = retorno.data.id;
          this.cedente.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/financeiro/cedente");
          this.$router.go("/financeiro/cedente");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/financeiro/cedentes");
          }, 2000);
        }
        this.carregarContasCedente();
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    async carregarBancos() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/banco/receber");
        if (response.data) {
          this.bancos = response.data;
          this.bancos.forEach((element) => {
            element.nome = element.numero + " - " + element.nome;
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de bancos";
        this.snackErro = true;
      }
    },

    async carregarFormasPgto() {
      this.formasPgto = [];
      if (this.cedente.forma) {
        this.defineLayouts(this.bancoSelecionado);
        try {
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/forma-pagamento/lista/tipo/" + this.cedente.forma);
          if (response.data) {
            this.formasPgto = response.data;
          }
        } catch {
          this.isUpdating = true;
          this.mensagem = "Ocorreu um erro ao carregar a lista de formas de pagamento";
          this.snackErro = true;
        }
      }
    },

    async carregarContas() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/movimento");
        if (response.data) {
          this.contasMovimento = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as contas movimento";
        this.snackErro = true;
      }
    },

    async carregarContasExtrato() {
      if (this.cedente.forma == "EXTRATO") {
        try {
          const responseAp = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/aplicacao");
          if (responseAp.data) {
            this.contasAplicacao = responseAp.data;
          }

          const responseHis = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/lista?idSite=" + this.idSitePrincipal);
          if (responseHis.data) {
            this.contasHistoricos = responseHis.data;
            this.contasHistoricos.forEach((element) => {
              element.nomeS = element.nome;
              element.nome = element.nome + " - " + element.mae;
            });
          }
        } catch {
          this.isUpdating = true;
          this.mensagem = "Ocorreu um erro ao carregar a lista de contas";
          this.snackErro = true;
        }
      }
    },

    async carregarContasCedente() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente-conta-historico/cedente/" + this.cedente.id);
        if (response.data) {
          this.cedenteContasHistoricos = response.data;
          this.cedenteContasHistoricos.forEach((element) => {
            element.historicoC = element.codHistorico + (element.historico ? " - " + element.historico : "");
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as contas dos históricos";
        this.snackErro = true;
      }
    },

    editarContaHistorico(item) {
      this.contaHistoricoIndex = this.cedenteContasHistoricos.indexOf(item);
      this.contaHistoricoItem = Object.assign({}, item);
      this.contaHistoricoSelecionada = {
        id: item.idConta,
        nome: item.nome
      };
      this.dialogContaHistorico = true;
    },
    removerContaHistorico(item) {
      this.contaHistoricoIndex = this.cedenteContasHistoricos.indexOf(item);
      this.contaHistoricoItem = Object.assign({}, item);
      this.cedenteContasHistoricos.splice(this.contaHistoricoIndex, 1);
      this.contaHistoricoItem = Object.assign({}, this.defaultContaHistoricoItem);
      this.contaHistoricoIndex = -1;
      this.contaHistoricoSelecionada = {};
    },
    closeContaHistorico() {
      this.dialogContaHistorico = false;
      setTimeout(() => {
        this.contaHistoricoItem = Object.assign({}, this.defaultContaHistoricoItem);
        this.contaHistoricoIndex = -1;
        this.contaHistoricoSelecionada = {};
        this.$refs.formContaHistorico.resetValidation();
      }, 300);
    },
    saveContaHistorico() {
      if (this.$refs.formContaHistorico.validate()) {
        this.contaHistoricoItem.historicoC = this.contaHistoricoItem.codHistorico + (this.contaHistoricoItem.historico ? " - " + this.contaHistoricoItem.historico : "");
        if (this.contaHistoricoIndex > -1) {
          Object.assign(this.cedenteContasHistoricos[this.contaHistoricoIndex], this.contaHistoricoItem);
        } else {
          this.cedenteContasHistoricos.push(this.contaHistoricoItem);
        }
        this.closeContaHistorico();
        this.contaHistoricoItem = Object.assign({}, this.defaultContaHistoricoItem);
        this.contaHistoricoIndex = -1;
        this.contaHistoricoSelecionada = {};
        this.$refs.formContaHistorico.resetValidation();
        this.validacao.contasHistoricos = false;
      }
    },
    defineLayouts(val) {
      if (val) {
        this.layouts = [];
        if (this.cedente.forma == "BOLETO") {
          if (["085", "133", "001", "041", "756"].includes(val.numero)) {
            this.layouts.push("CNAB240");
          }
          if (["041", "756", "748"].includes(val.numero)) {
            this.layouts.push("CNAB400");
          }
        } else if (this.cedente.forma == "DEBITO_CONTA") {
          if (["237", "001", "104", "041", "341", "748", "033"].includes(val.numero)) {
            this.layouts.push("CNAB150");
          }
          if (["237"].includes(val.numero)) {
            this.layouts.push("CNAB400");
          }
        }
      }
    },

    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    }
  },

  watch: {
    contaHistoricoSelecionada(val) {
      this.contaHistoricoItem.idConta = val.id;
      this.contaHistoricoItem.nome = val.nome;
    },
    bancoSelecionado(val) {
      if (val) {
        this.defineLayouts(val);
      }
    }
  },

  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach((element) => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA") {
        this.direitoCriar = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    await this.carregarBancos();
    await this.carregarContas();

    //* Se é edição, carrega o elemento
    if (this.id == "") {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA"]);
    } else {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA"]);
      try {
        //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/financeiro/cedentes");
        } else {
          this.cedente = response.data;

          var temp1 = this.cedente.taxaCobrancaBoleto;
          var temp2 = this.cedente.taxaRegistro;
          var temp3 = this.cedente.taxaCobrancaDC;
          var temp4 = this.cedente.taxaTransacao;
          var temp5 = this.cedente.vlrOutrosAcrescimos;
          var temp6 = this.cedente.percentualMulta;
          var temp7 = this.cedente.percentualJurosDia;

          this.cedente.taxaCobrancaBoleto = "0,00";
          this.cedente.taxaRegistro = "0,00";
          this.cedente.taxaCobrancaDC = "0,00";
          this.cedente.taxaTransacao = "0,00";
          this.cedente.vlrOutrosAcrescimos = "0,00";
          this.cedente.percentualMulta = "0,00";
          this.cedente.percentualJurosDia = "0,00";
          setTimeout(() => {
            this.cedente.taxaCobrancaBoleto = temp1;
            this.cedente.taxaRegistro = temp2;
            this.cedente.taxaCobrancaDC = temp3;
            this.cedente.taxaTransacao = temp4;
            this.cedente.vlrOutrosAcrescimos = temp5;
            this.cedente.percentualMulta = temp6;
            this.cedente.percentualJurosDia = temp7;
          }, 300);

          this.bancos.forEach((banco) => {
            if (banco.id == this.cedente.idBanco) {
              this.bancoSelecionado = banco;
            }
          });
          if (this.cedente.origens) {
            this.origens = this.cedente.origens.split(",");
          }
          await this.carregarFormasPgto();
          this.cedente.contasAplicacao = [];
          if (this.cedente.contas) {
            var itens = this.cedente.contas.split(",");
            itens.forEach((element) => {
              this.cedente.contasAplicacao.push(parseInt(element));
            });
          }
          if (this.cedente.forma == "EXTRATO") {
            await this.carregarContasExtrato();
            await this.carregarContasCedente();
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados do cedente";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
