<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">ac_unit</v-icon>
          <v-toolbar-title style="color: white" class="font-weight-light">Roteiro de Entrega {{ vendaAvulsa ? "de Venda Avulsa" : "de Assinaturas" }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="blue" fab small :loading="loading">
            <v-icon color="white" title="Imprimir Roteiro" @click="acaoRoteiro('imprimir')">print</v-icon>
          </v-btn>
          <v-btn color="orange darken-2" fab small :loading="loading" class="ma-1">
            <v-icon color="white" title="Imprimir Rótulos" @click="acaoRoteiro('rotulos')">print</v-icon>
          </v-btn>
          <v-btn v-if="!vendaAvulsa && idSitePrincipal == 18" color="orange lighten-1" fab small :loading="loading" @click="acaoRoteiro('exportar')" class="ma-1">
            <v-icon color="white" title="Exportar">description</v-icon>
          </v-btn>
          <v-btn v-if="!vendaAvulsa" color="orange lighten-3" fab small :loading="loading" @click="acaoRoteiro('exportarCSV')">
            <v-icon color="white" title="Exportar CSV">description</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container fluid grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
            </v-flex>

            <v-flex xs12>
              <h4 class="overline font-weight-black font-italic">Filtrar Logradouros</h4>
              <v-divider></v-divider>
            </v-flex>

            <v-flex xs12 md4>
              <v-autocomplete
                autofocus
                v-model="idMunicipio"
                :items="municipios"
                :filter="filtrarObjetos"
                :label="$store.state.usuario.site_pais == 'BR' ? 'Município' : 'Concelho'"
                item-text="nome"
                item-value="id"
                no-data-text
                clearable
              ></v-autocomplete>
            </v-flex>

            <v-flex xs12 md4>
              <v-autocomplete
                v-model="idEntregador"
                :items="entregadores"
                :filter="filtrarObjetos"
                label="Entregador"
                item-text="nome"
                item-value="id"
                no-data-text
                clearable
              ></v-autocomplete>
            </v-flex>

            <v-flex xs12 md4>
              <v-autocomplete
                v-model="idRota"
                :items="rotas"
                :filter="filtrarObjetos"
                label="Rota"
                item-text="nome"
                item-value="id"
                no-data-text
                clearable
                multiple
              ></v-autocomplete>
            </v-flex>

            <v-flex xs12 md1>
              <v-autocomplete
                v-model="idEntrega"
                :items="simnao"
                :filter="filtrarObjetos"
                label="Endereço de Entrega"
                item-text="nome"
                item-value="id"
                no-data-text
                clearable
              ></v-autocomplete>
            </v-flex>

            <v-flex xs12 md1>
              <v-autocomplete
                v-model="idSituacao"
                :items="situacoes"
                :filter="filtrarObjetos"
                label="Situação"
                item-text="nome"
                item-value="id"
                no-data-text
                clearable
              ></v-autocomplete>
            </v-flex>

            <v-flex xs12 md2>
              <v-autocomplete
                v-if="!vendaAvulsa"
                v-model="idRenovacao"
                :items="itensRenovacao"
                :filter="filtrarObjetos"
                label="Renovação"
                item-text="nome"
                item-value="id"
                no-data-text
                clearable
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.descricao"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-flex>

            <v-flex xs12 md2>
              <v-autocomplete
                v-if="!vendaAvulsa"
                v-model="idStatus"
                :items="itensStatus"
                :filter="filtrarObjetos"
                label="Status dos Contratos"
                item-text="nome"
                item-value="id"
                no-data-text
                clearable
                @change="reclamacoes = idStatus == 3 ? 1 : reclamacoes"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.descricao"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-flex>

            <v-flex xs12 md2>
              <v-autocomplete
                v-if="!vendaAvulsa"
                v-model="reclamacoes"
                :items="simNaoSomente"
                :filter="filtrarObjetos"
                label="Incluir Reclamações"
                item-text="nome"
                item-value="id"
                no-data-text
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.descricao"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-flex>

            <v-flex xs12 md4>
              <v-text-field
                background-color="white"
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                :placeholder="'Pesquisa pelo ' + ($store.state.usuario.site_pais == 'BR' ? 'bairro' : 'freguesia/localidade') + ', CEP, logradouro'"
                hide-details
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
            </v-flex>

            <v-flex xs12>
              <h4 class="overline font-weight-black font-italic">Associar Logradouros Selecionados a um Entregador/Rota</h4>
              <v-divider></v-divider>
            </v-flex>

            <v-flex xs12 md5>
              <v-autocomplete
                v-model="idEntregadorDef"
                :items="entregadores"
                :filter="filtrarObjetos"
                label="Entregador a Associar"
                item-text="nome"
                item-value="id"
                no-data-text
                clearable
              ></v-autocomplete>
            </v-flex>

            <v-flex xs12 md5>
              <v-autocomplete
                v-model="idRotaDef"
                :items="rotas"
                :filter="filtrarObjetos"
                label="Rota a Associar"
                item-text="nome"
                item-value="id"
                no-data-text
                clearable
              ></v-autocomplete>
            </v-flex>

            <v-flex xs2 md2 text-center>
              <v-btn
                :loading="isUpdating"
                color="green white"
                class="btn-rsn mt-4"
                style="color: white"
                depressed
                title="Selecione os logradouros abaixo e o entregador e a rota ao lado e clique aqui"
                @click="definir"
              >
                <v-icon class="icon-rsn" left>arrow_forward</v-icon>Associar
              </v-btn>
            </v-flex>

            <v-flex xs8 md3 ml-4>
              <v-checkbox v-model="selectAll" :label="selectAll ? 'Desmarcar todos' : 'Marcar todos da página'" @change="marcarDesmarcarTodos"></v-checkbox>
            </v-flex>
          </v-layout>
          <v-data-table
            mobile-breakpoint="0"
            :headers="vendaAvulsa ? headersVA : headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:[getItemActions()]="{ item }">
              <span :class="item.selecionado == 1 ? 'grey lighten-2' : ''">
                <v-icon v-if="item.id" color="blue" title="Selecionar" @click="ativarDesativarItem(item)">
                  {{ item.selecionado == 1 ? "check_box" : "check_box_outline_blank" }}
                </v-icon>
                <v-icon v-if="item.id && !vendaAvulsa" color="blue" title="Visualizar Assinaturas" @click="visualizarEnderecos(item)">loupe</v-icon>
                <v-icon v-if="item.id && vendaAvulsa" color="blue" title="Visualizar Bancas" @click="visualizarBancas(item)">loupe</v-icon>
              </span>
            </template>

            <template v-slot:[getItemEntrega()]="{ item }">
              <span v-html="item.entrega == 1 ? 'Sim' : item.entrega == 0 ? '<strong>Não</strong>' : ''"></span>
            </template>

            <template v-slot:[getItemSituacao()]="{ item }">
              <span v-html="item.situacao == 1 ? 'Ativo' : item.situacao == 0 ? '<strong>Inativo</strong>' : ''"></span>
            </template>

            <template v-slot:[getItemMunicipio()]="{ item }">{{ item.municipio ? item.municipio + "/" + item.uf : "" }}</template>

            <template v-slot:[getItemLogradouro()]="{ item }">
              {{ item.tipo + " " + item.logradouro }}
            </template>
          </v-data-table>

          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 15" circle></v-pagination>
          </div>

          <v-dialog persistent v-model="dialogEndereco" max-width="600px" @keydown.esc="dialogEndereco = false">
            <v-card>
              <v-toolbar>
                <span class="headline">Relação de Assinaturas</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeEndereco">
                  <v-icon color="darken-1">cancel</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md12>
                      <v-data-table
                        mobile-breakpoint="0"
                        :headers="headerAssinaturas"
                        :items="assinaturasCEP"
                        no-data-text
                        :footer-props="{
                          itemsPerPageText: 'Registros por página',
                          itemsPerPageOptions: [10],
                          pageText: ''
                        }"
                      >
                        <template v-slot:[getItemActions()]="{ item }">
                          <v-icon v-if="direitoClientes" color="orange" title="Cliente" @click="visualizarCliente(item)">account_circle</v-icon>
                          <v-icon v-if="direitoAssinaturas" color="red" title="Assinatura" @click="visualizarAssinatura(item)">assignment</v-icon>
                        </template>
                      </v-data-table>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" @click="closeEndereco">Retornar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog persistent v-model="dialogBanca" max-width="600px" @keydown.esc="dialogBanca = false">
            <v-card>
              <v-toolbar>
                <span class="headline">Relação de Bancas</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeBanca">
                  <v-icon color="darken-1">cancel</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md12>
                      <v-data-table
                        mobile-breakpoint="0"
                        :headers="headerBancas"
                        :items="bancasCEP"
                        no-data-text
                        :footer-props="{
                          itemsPerPageText: 'Registros por página',
                          itemsPerPageOptions: [10],
                          prevIcon: '',
                          nextIcon: '',
                          pageText: ''
                        }"
                      >
                        <template v-slot:[getItemActions()]="{ item }">
                          <v-icon v-if="direitoBancas" color="orange" title="Banca" @click="visualizarBanca(item)">account_circle</v-icon>
                        </template>
                      </v-data-table>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" @click="closeBanca">Retornar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>

      <v-dialog persistent v-model="dialogRoteiro" max-width="390">
        <v-card>
          <v-toolbar>
            <span class="headline">{{ acao == "rotulos" ? "Rótulos" : "Roteiro" }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeRoteiro">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form ref="formRoteiro" v-model="roteiroValid" lazy-validation>
            <v-container>
              <v-layout wrap>
                <v-flex xs12 md12 v-if="siteGrupo.length > 0">
                  <v-autocomplete
                    v-model="idSite"
                    :disabled="isUpdating"
                    :items="siteGrupo"
                    :filter="filtrarObjetos"
                    label="Restrito ao Site"
                    item-text="nome"
                    item-value="id"
                    autocomplete="disabled"
                    :name="Math.random()"
                    no-data-text
                    clearable
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    autofocus
                    v-model="idProduto"
                    :items="produtos"
                    :filter="filtrarObjetos"
                    hint="Obrigatório"
                    :rules="[(value) => !!value || 'Obrigatório.']"
                    label="Produto"
                    item-text="nome"
                    item-value="id"
                    no-data-text
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 v-if="acao == 'rotulos'">
                  <v-autocomplete
                    v-model="idEntregador"
                    :items="entregadores"
                    :filter="filtrarObjetos"
                    label="Entregador"
                    item-text="nome"
                    item-value="id"
                    hint="Obrigatório"
                    :rules="[(value) => !!value || 'Obrigatório.']"
                    no-data-text
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 v-if="acao == 'rotulos'">
                  <v-text-field
                    v-model="quebra"
                    label="Quebra"
                    v-mask="'###'"
                    hint="Obrigatório"
                    :rules="[(value) => !!value || 'Obrigatório.', (value) => value > 9 || 'Quebra inválida']"
                    color="blue-grey lighten-2"
                    autocomplete="disabled"
                    :name="Math.random()"
                  ></v-text-field>
                </v-flex>
                <v-flex offset-xs1 md10>
                  <v-menu v-model="mnuDtEntrega" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dtEntrega"
                        label="Data da Entrega"
                        v-mask="'##/##/####'"
                        hint="Obrigatório"
                        :rules="[(value) => !!value || 'Obrigatório.', (value) => valida_data(value) || 'Data inválida']"
                        prepend-icon="event"
                        v-on="on"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @keyup="dtEntregaUS = valida_data(dtEntrega) ? formatDateUS(dtEntrega) : null"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dtEntregaUS" locale="pt-br" no-title @input="mnuDtEntrega = false"></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-switch
                    v-if="vendaAvulsa == 0"
                    v-model="sobrepor"
                    :label="`${sobrepor == 1 ? 'Gerar novamente' : 'Não gerar novamente'}`"
                    :disabled="
                      (acao == 'rotulos' && !(!idMunicipio && !idRota)) ||
                      (acao != 'rotulos' && !(!idMunicipio && !idEntregador && !idRota && (idStatus == null || idStatus == 3) && !idRenovacao && reclamacoes != 2))
                    "
                  ></v-switch>
                </v-flex>
                <v-flex xs12>
                  <v-switch
                    v-if="vendaAvulsa == 0 && acao != 'rotulos' && (idStatus == null || idStatus == 1)"
                    v-model="condominios"
                    :label="`${condominios == 1 ? 'Somente condomínios' : 'Todos os registros'}`"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 v-if="condominios">
                  <v-autocomplete
                    autofocus
                    v-model="condominio"
                    :items="endCondominios"
                    :filter="filtrarObjetos"
                    label="Condomínio"
                    item-text="nome"
                    item-value="id"
                    no-data-text="Carregando condomínios..."
                    return-object
                    :loading="endCondominios.length == 0"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title v-html="`${data.item.tipo} ${data.item.logradouro}, ${data.item.numero} - ${data.item.bairro}`"></v-list-item-title>
                        <v-list-item-subtitle v-html="`${data.item.cep} - ${data.item.municipio}/${data.item.uf}`"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="closeRoteiro">Retornar</v-btn>
            <v-btn color="blue darken-1" @click="imprimirRoteiro">Concluir</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import util from "../../util";
import download from "downloadjs";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "80" },
        { text: "ID", value: "id" },
        { text: "Entrega", value: "entrega" },
        { text: "Situação", value: "situacao", divider: "true" },
        { text: "Entregador", value: "entregador" },
        { text: "Rota", value: "rota", divider: "true" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Município/UF" : "Concelho/Distrito",
          value: "municipio"
        },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Bairro" : "Freguesia/Localidade",
          value: "bairro"
        },
        { text: "CEP", value: "cep" },
        { text: "Logradouro", value: "logradouro" },
        { text: "Faixa", value: "faixa" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      headersVA: [
        { text: "Ações", value: "actions", width: "80" },
        { text: "ID", value: "id" },
        { text: "Entrega", value: "entrega" },
        { text: "Situação", value: "situacao", divider: "true" },
        // { text: "Entregador Assinatura", value: "entregador" },
        // { text: "Rota Assinatura", value: "rota", divider: "true" },
        { text: "Entregador", value: "entregadorVA" },
        { text: "Rota", value: "rotaVA", divider: "true" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Município/UF" : "Concelho/Distrito",
          value: "municipio"
        },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Bairro" : "Freguesia/Localidade",
          value: "bairro"
        },
        { text: "CEP", value: "cep" },
        { text: "Logradouro", value: "logradouro" },
        { text: "Faixa", value: "faixa" }
      ],
      headerAssinaturas: [
        { text: "Ações", value: "actions", width: "80" },
        { text: "Cliente", value: "nome" },
        { text: "Assinatura", value: "idAssinatura" },
        { text: "Status", value: "status" }
      ],
      headerBancas: [
        { text: "Ações", value: "actions" },
        { text: "Banca", value: "nome" }
      ],

      selectAll: 0,

      mensagemPesquisa: "",
      parametrosPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      options: {},
      idSitePrincipal: null,
      idSite: null,
      siteGrupo: [],

      ordenacao: "",
      ordem: "",

      idEntrega: 1,
      simnao: [
        { id: 1, nome: "Sim" },
        { id: 0, nome: "Não" }
      ],
      simNaoSomente: [
        { id: 1, nome: "Sim", descricao: "Inclui reclamações no roteiro" },
        { id: 0, nome: "Não", descricao: "Não inclui reclamações no roteiro" },
        {
          id: 2,
          nome: "Somente",
          descricao: "Apresenta somente registros com reclamações no roteiro"
        }
      ],
      idSituacao: 1,
      situacoes: [
        { id: 1, nome: "Ativos" },
        { id: 0, nome: "Inativos" }
      ],
      idStatus: null,
      itensStatus: [
        { id: 1, nome: "Correntes", descricao: "CORRENTE" },
        {
          id: 0,
          nome: "Não Correntes",
          descricao: "SUSPENSA, RECUPERAÇÃO, CANCELADA, EXPIRADA"
        },
        {
          id: 2,
          nome: "Alterados",
          descricao: "Endereço alterado nos últimos 7 dias"
        },
        {
          id: 3,
          nome: "Movimento",
          descricao: "Novas, Endereços Alterados, Suspensas, Canceladas, Expiradas"
        }
      ],
      idRenovacao: 0,
      itensRenovacao: [
        { id: 0, nome: "Ambos", descricao: "Novos e Renovados" },
        { id: 1, nome: "Novos", descricao: "Renovação 0 ou Reiniciados" },
        {
          id: 2,
          nome: "Renovados",
          descricao: "Renovação 1 em diante (não reiniciados)"
        }
      ],
      reclamacoes: 0,
      idProduto: null,
      produtos: [],
      idMunicipio: null,
      municipios: [],
      idEntregador: null,
      idEntregadorDef: null,
      entregadores: [],
      idRota: null,
      idRotaDef: null,
      rotas: [],
      quebra: 0,

      dialogEndereco: false,
      assinaturasCEP: [],

      dialogBanca: false,
      bancasCEP: [],

      dialogRoteiro: false,
      acao: "",
      roteiroValid: true,
      dtEntrega: null,
      dtEntregaUS: null,
      mnuDtEntrega: false,
      sobrepor: 0,
      condominios: 0,
      condominio: null,
      endCondominios: [],

      direitoAssinaturas: false,
      direitoClientes: false,
      direitoBancas: false,

      mensagem: "",
      snackbar: false,
      snackErro: false,
      isUpdating: false,
      loader: null,
      loading: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },
    getItemEntrega() {
      return "item.entrega";
    },
    getItemSituacao() {
      return "item.situacao";
    },
    getItemMunicipio() {
      return "item.municipio";
    },
    getItemLogradouro() {
      return "item.logradouro";
    },
    limpaFiltro() {
      this.paginaAtual = 1;
      setTimeout(() => {
        this.pesquisaDados();
      }, 300);
    },
    async pesquisaDados() {
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";
      this.registros = [];

      this.parametrosPesquisa = "";
      if (this.idMunicipio) {
        this.parametrosPesquisa += "&idMunicipio=" + this.idMunicipio;
      }
      if (this.idEntregador) {
        this.parametrosPesquisa += "&idEntregador=" + this.idEntregador;
      }
      if (this.idRota && this.idRota.length > 0) {
        this.parametrosPesquisa += "&idRota=" + this.idRota.join(",");
      }
      if (this.idEntrega != null) {
        this.parametrosPesquisa += "&entrega=" + this.idEntrega;
      }
      if (this.idSituacao != null) {
        this.parametrosPesquisa += "&situacao=" + this.idSituacao;
      }
      if (this.idStatus != null && this.idStatus != 3) {
        this.parametrosPesquisa += "&status=" + this.idStatus;
      }
      if (this.idRenovacao) {
        this.parametrosPesquisa += "&renovacao=" + this.idRenovacao;
      }
      if (this.$route.query["idCEP"]) {
        this.parametrosPesquisa = "&idCEP=" + this.$route.query["idCEP"];
      }

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/cep/logradouros?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            this.parametrosPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem +
            (this.vendaAvulsa ? "&vendaAvulsa=1" : "")
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.registros.forEach((element) => {
          element.rota = element.rota ? element.rota + " - " + element.municipioRota + "/" + element.ufRota : "";
          element.rotaVA = element.rotaVA ? element.rotaVA + " - " + element.municipioRotaVA + "/" + element.ufRotaVA : "";
        });
        this.selectAll = 0;

        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e && e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else if (e.response && e.response.data && e.response.data.length > 0) {
          this.mensagemPesquisa = "ERRO: " + e.response.data[0].mensagem;
          this.mensagem = "ERRO: " + e.response.data[0].mensagem;
          this.snackErro = true;
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
          this.mensagem = "Erro ao buscar dados: " + e;
          this.snackErro = true;
        }
      }
    },

    async carregarProdutos() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/produto/lista?" + (this.vendaAvulsa ? "vendaAvulsa=1" : "tipo=IMPRESSO"));
      if (response && response.data) {
        this.produtos = response.data;
        if (this.produtos.length > 0) {
          this.idProduto = this.produtos[0].id;
        }
      }
    },

    async carregarMunicipiosRoteiro() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cep/municipios");
      if (response && response.data) {
        this.municipios = response.data;
        this.municipios.forEach((element) => {
          element.nome = element.nome + "/" + element.uf;
        });
      }
    },

    async carregarEntregadores() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/entregador/lista?idSite=" + this.idSitePrincipal + (this.vendaAvulsa ? "&vendaAvulsa=1" : ""));
      if (response && response.data) {
        this.entregadores = response.data;
      }
    },

    async carregarRotas() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/rota/lista?idSite=" + this.idSitePrincipal + (this.vendaAvulsa ? "&vendaAvulsa=1" : ""));
      if (response && response.data) {
        this.rotas = response.data;
        this.rotas.forEach((element) => {
          element.nome = element.nome + " - " + element.municipio + "/" + element.uf;
        });
      }
    },

    async carregarAssinaturasCEP(idCEP) {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/renovacao-edicao-endereco/cep/" + idCEP);
        if (response.data) {
          this.assinaturasCEP = response.data;
        }
      } catch (e) {
        this.mensagem = e;
        this.snackErro = true;
      }
    },

    async carregarBancasCEP(idCEP) {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-banca/cep/" + idCEP);
        if (response.data) {
          this.bancasCEP = response.data;
        }
      } catch (e) {
        this.mensagem = e;
        this.snackErro = true;
      }
    },

    acaoRoteiro(acao) {
      this.acao = acao;
      this.dialogRoteiro = true;
      this.sobrepor = 0;
      this.condominios = 0;
      this.endCondominios = [];
      if (acao != "rotulos") {
        if (this.idMunicipio || this.idEntregador || this.idRota || (this.idStatus != null && this.idStatus != 3) || this.idRenovacao || this.reclamacoes == 2) {
          this.sobrepor = 1;
        }
      } else {
        if (this.idMunicipio || this.idRota) {
          this.sobrepor = 1;
        }
      }
    },
    closeRoteiro() {
      this.dialogRoteiro = false;
      this.idEntregador = null;
    },
    imprimirRoteiro() {
      if (!this.$refs.formRoteiro.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      if (this.acao == "imprimir") {
        this.imprimir();
      } else if (this.acao == "rotulos") {
        this.rotulos();
      } else {
        this.exportar();
      }
      this.closeRoteiro();
    },
    async imprimir() {
      this.loader = "loading";
      const l = this.loader;
      this[l] = !this[l];

      try {
        var parametros = "";
        if (this.idMunicipio) {
          parametros += "&idMunicipio=" + this.idMunicipio;
        }
        if (this.idEntregador) {
          parametros += "&idEntregador=" + this.idEntregador;
        }
        if (this.idRota) {
          parametros += "&idRota=" + this.idRota;
        }
        if (this.idStatus != null && this.idStatus != 3) {
          parametros += "&status=" + (this.idStatus == 1 ? "CORRENTES" : this.idStatus == 0 ? "NÃO CORRENTES" : "ALTERADOS");
        }
        if (this.idRenovacao) {
          parametros += "&renovacao=" + this.idRenovacao;
        }
        if (this.reclamacoes) {
          parametros += "&reclamacoes=" + this.reclamacoes;
        }
        if (this.idStatus != null && this.idStatus == 3) {
          parametros += "&movimento=1";
        } else if (this.condominios) {
          if (this.idStatus == null || this.idStatus == 1) {
            parametros += "&condominios=1";
            if (this.condominio) {
              parametros += "&idCEP=" + this.condominio.idCEP + "&numero=" + this.condominio.numero;
            }
          }
        }

        if (this.sobrepor) {
          parametros += "&sobrepor=1";
        }

        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/cep/roteiro" +
            (this.vendaAvulsa ? "VA" : "") +
            "?dtRoteiro=" +
            this.dtEntregaUS +
            (this.idSite ? "&idSite=" + this.idSite : "") +
            "&idProduto=" +
            this.idProduto +
            "&filtro=" +
            this.filtroPesquisa +
            "&site=" +
            this.$store.state.usuario.site_nome +
            parametros
        );

        util.imprimir(response.data);
      } catch (e) {
        this.mensagem = e.response && e.response.data;
        this.snackErro = true;
      } finally {
        this[l] = false;
        this.loader = null;
      }
    },

    async rotulos() {
      this.loader = "loading";
      const l = this.loader;
      this[l] = !this[l];

      try {
        var parametros = "";
        if (this.idMunicipio) {
          parametros += "&idMunicipio=" + this.idMunicipio;
        }
        if (this.idEntregador) {
          parametros += "&idEntregador=" + this.idEntregador;
        }
        if (this.idRota) {
          parametros += "&idRota=" + this.idRota;
        }
        if (this.sobrepor) {
          parametros += "&sobrepor=1";
        }
        if (this.quebra) {
          parametros += "&quebra=" + this.quebra;
        }

        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/cep/rotulo" +
            (this.vendaAvulsa ? "VA" : "") +
            "?dtRoteiro=" +
            this.dtEntregaUS +
            (this.idSite ? "&idSite=" + this.idSite : "") +
            "&idProduto=" +
            this.idProduto +
            "&site=" +
            this.$store.state.usuario.site_nome +
            parametros
        );

        util.imprimir(response.data);
      } catch (e) {
        this.mensagem = e.response && e.response.data ? (e.response.data.length ? e.response.data[0].mensagem : e.response.data) : e.code ? e.code : e;
        this.snackErro = true;
      } finally {
        this[l] = false;
        this.loader = null;
      }
    },

    async exportar() {
      this.loader = "loading";
      const l = this.loader;
      this[l] = !this[l];

      try {
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/cep/roteiro?dtRoteiro=" +
            this.dtEntregaUS +
            (this.idSite ? "&idSite=" + this.idSite : "") +
            "&idProduto=" +
            this.idProduto +
            "&site=" +
            this.$store.state.usuario.site_nome +
            (this.acao == "exportar" ? "&txt=1" : "&csv=1") +
            (this.sobrepor ? "&sobrepor=1" : "")
        );

        if (this.acao == "exportar") {
          download(response.data, "roteiro.txt", "text/plain");
        } else {
          download(response.data, "roteiro.csv", "text/plain");
        }
      } catch (e) {
        this.carregando = false;
        this.mensagemPesquisa = "Erro ao montar o roteiro: " + e;
      } finally {
        this[l] = false;
        this.loader = null;
      }
    },

    ativarDesativarItem(item) {
      var dadoIndex = this.registros.indexOf(item);
      var dadoItem = Object.assign({}, item);
      dadoItem.selecionado = dadoItem.selecionado == 1 ? 0 : 1;
      Object.assign(this.registros[dadoIndex], dadoItem);
    },

    async definir() {
      if (this.idEntregadorDef && this.idRotaDef) {
        var _entregador_nome = "";
        this.entregadores.forEach((element) => {
          if (element.id == this.idEntregadorDef) {
            _entregador_nome = element.nome;
            return false;
          }
        });
        var _rota_nome = "";
        this.rotas.forEach((element) => {
          if (element.id == this.idRotaDef) {
            _rota_nome = element.nome;
            return false;
          }
        });

        var itens = "";
        this.registros.forEach((element) => {
          if (element.selecionado == 1) {
            var dadoIndex = this.registros.indexOf(element);
            var dadoItem = Object.assign({}, element);
            itens += (itens == "" ? "" : ",") + dadoItem.id;
            if (this.vendaAvulsa) {
              dadoItem.entregadorVA = _entregador_nome;
              dadoItem.rotaVA = _rota_nome;
            } else {
              dadoItem.entregador = _entregador_nome;
              dadoItem.rota = _rota_nome;
            }
            dadoItem.selecionado = 0;
            dadoItem.entrega = 1;
            dadoItem.situacao = 1;
            Object.assign(this.registros[dadoIndex], dadoItem);
          }
        });

        if (itens) {
          var roteiroCommit = {};
          if (this.vendaAvulsa) {
            roteiroCommit = {
              entrega: 1,
              roteiro: 1,
              idEntregadorVA: this.idEntregadorDef,
              idRotaVA: this.idRotaDef,
              situacao: 1,
              itens: itens
            };
          } else {
            roteiroCommit = {
              entrega: 1,
              roteiro: 0,
              idEntregador: this.idEntregadorDef,
              idRota: this.idRotaDef,
              situacao: 1,
              itens: itens
            };
          }

          this.isUpdating = true;
          try {
            await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cep/roteiro", roteiroCommit);
            this.mensagem = "Dados foram atualizados";
            this.snackbar = true;
          } catch (e) {
            if (e.response && e.response.data) {
              this.mensagem =
                "ERRO: " +
                (e.toString().includes("status code 400")
                  ? "SESSÃO EXPIRADA"
                  : e.response && e.response.data && e.response.data.length > 0
                  ? e.response.data[0].mensagem
                  : "Indeterminado, verifique sua conexão");
              this.snackErro = true;
            }
          } finally {
            this.isUpdating = false;
          }
        } else {
          this.mensagem = "Selecione pelo menos um logradouro para associar";
          this.snackErro = true;
        }
      } else {
        this.mensagem = "Selecione um Entregador e uma Rota a Associar";
        this.snackErro = true;
      }
    },

    async visualizarAssinatura(item) {
      if (this.direitoAssinaturas) {
        window.open("/assinaturas/assinatura/" + item.idAssinatura, "_blank");
      }
    },
    async visualizarCliente(item) {
      if (this.direitoClientes) {
        window.open("/assinaturas/assinante/" + item.idCliente, "_blank");
      }
    },

    visualizarEnderecos(item) {
      this.carregarAssinaturasCEP(item.id);
      this.dialogEndereco = true;
    },
    closeEndereco() {
      this.dialogEndereco = false;
    },

    async visualizarBanca(item) {
      if (this.direitoClientes) {
        window.open("/assinaturas/banca/" + item.idCliente, "_blank");
      }
    },
    visualizarBancas(item) {
      this.carregarBancasCEP(item.id);
      this.dialogBanca = true;
    },
    closeBanca() {
      this.dialogBanca = false;
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    marcarDesmarcarTodos() {
      this.registros.forEach((element) => {
        element.selecionado = this.selectAll;
      });
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_ROTEIRO_ACESSO"]);
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach((element) => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_EDITAR") {
        this.direitoAssinaturas = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_EDITAR") {
        this.direitoClientes = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_BANCAS_EDITAR") {
        this.direitoBancas = true;
      }
    });
  },

  async created() {
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    this.carregarProdutos();
    this.carregarMunicipiosRoteiro();
    this.carregarEntregadores();
    this.carregarRotas();
    var amanha = new Date();
    amanha.setDate(amanha.getDate() + 1);
    this.dtEntregaUS = util.dateToStringUS(amanha);
  },

  watch: {
    $route() {
      //      this.$router.go(to.path);
      this.idEntregador = null;
      this.idRota = null;

      this.carregarEntregadores();
      this.carregarRotas();

      this.pesquisaDados();
    },
    dialogEndereco(val) {
      val || this.closeEndereco();
    },
    dialogBanca(val) {
      val || this.closeBanca();
    },
    dialogRoteiro(val) {
      val || this.closeRoteiro();
    },
    dtEntregaUS(val) {
      this.dtEntrega = util.formatDate(val);
      this.mnuDtEntrega = false;
      this.condominios = 0;
      this.endCondominios = [];
    },
    paginaAtual() {
      this.pesquisaDados();
    },
    async condominios(val) {
      if (val) {
        this.sobrepor = 1;
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cep/condominios?idSite=" + this.idSitePrincipal + "&dtRoteiro=" + this.dtEntregaUS);
        if (response && response.data) {
          response.data.forEach((element) => {
            element.nome = `${element.cep} - ${element.tipo} ${element.logradouro}, ${element.numero} - ${element.bairro} - ${element.municipio}/${element.uf}`;
            this.endCondominios.push(element);
          });
        }
      }
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados();
      }
    }
  },
  computed: {
    vendaAvulsa() {
      return this.$route.path.indexOf("venda-avulsa") > -1;
    }
  }
};
</script>
