<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar height="50" color="#333333">
      <v-icon color="white" class="mr-2">web_asset</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">Site</v-toolbar-title>
    </v-toolbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
      </v-flex>
    </v-layout>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0">
              <v-expansion-panel-header :class="validacao.dadosBasicos ? 'red--text' : ''">
                <strong>Dados Básicos</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">Informações do site</h4>
                      <v-divider></v-divider>
                    </v-flex>

                    <v-flex xs12 md10>
                      <v-text-field
                        autofocus
                        background-color="white"
                        v-model="site.nome"
                        label="Nome do Site"
                        maxlength="100"
                        hint="Obrigatório"
                        :rules="[
                          validacao.nome == false || validacao.nome,
                          (value) => !!value || 'Obrigatório.',
                          (value) => valida_nome(value) || 'Nome inválido',
                          (value) => value.length > 2 || 'Preencha o nome do site'
                        ]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="
                          site.nome = pLetra(site.nome);
                          validacao.nome = false;
                        "
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md2>
                      <v-text-field
                        background-color="white"
                        v-model="site.sigla"
                        label="Sigla"
                        maxlength="10"
                        hint="Obrigatório"
                        :rules="[validacao.sigla == false || validacao.sigla, (value) => !!value || 'Obrigatório.', (value) => value.length > 2 || 'Preencha a sigla']"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="
                          site.sigla = site.sigla.toLowerCase();
                          validacao.sigla = false;
                        "
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md5>
                      <v-autocomplete
                        :filter="filtrarObjetos"
                        v-model="site.idGrupo"
                        :disabled="isUpdating"
                        :items="grupos"
                        hint="Obrigatório"
                        :rules="[validacao.idGrupo == false || validacao.idGrupo]"
                        label="Grupo do Site"
                        item-text="nome"
                        item-value="id"
                        clearable
                        no-data-text
                        @change="validacao.idGrupo = false"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md5>
                      <v-autocomplete
                        :filter="filtrarObjetos"
                        v-model="site.idRede"
                        :rules="[validacao.idRede == false || validacao.idRede]"
                        :disabled="isUpdating"
                        :items="redes"
                        label="Rede do Site"
                        item-text="nome"
                        item-value="id"
                        clearable
                        no-data-text
                        @change="validacao.idRede = false"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md2>
                      <v-autocomplete
                        :filter="filtrarObjetos"
                        v-model="site.idPais"
                        :disabled="isUpdating"
                        :items="paises"
                        hint="Obrigatório"
                        :rules="[validacao.idPais == false || validacao.idPais, (value) => !!value || 'Obrigatório.']"
                        label="País"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="
                          validacao.idPais = false;
                          carregarUFs();
                        "
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">Dados Juridicos</h4>
                      <v-divider></v-divider>
                    </v-flex>

                    <v-flex xs12>
                      <v-text-field
                        background-color="white"
                        v-model="site.razaoSocial"
                        label="Razão Social"
                        maxlength="100"
                        hint="Obrigatório"
                        :rules="[
                          validacao.razaoSocial == false || validacao.razaoSocial,
                          (value) => !!value || 'Obrigatório.',
                          (value) => valida_nome(value) || 'Razão social inválida',
                          (value) => value.length > 2 || 'Preencha a razão social'
                        ]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="
                          site.razaoSocial = pLetra(site.razaoSocial);
                          validacao.razaoSocial = false;
                        "
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.nomeFantasia"
                        label="Nome Fantasia"
                        maxlength="100"
                        hint="Obrigatório"
                        :rules="[
                          validacao.nomeFantasia == false || validacao.nomeFantasia,
                          (value) => !!value || 'Obrigatório.',
                          (value) => valida_nome(value) || 'Nome fantasia inválido',
                          (value) => value.length > 2 || 'Preencha o nome fantasia'
                        ]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="
                          site.nomeFantasia = pLetra(site.nomeFantasia);
                          validacao.nomeFantasia = false;
                        "
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.enderecoSite"
                        label="Endereço (URL) do site"
                        maxlength="100"
                        hint="Obrigatório"
                        :rules="[
                          validacao.enderecoSite == false || validacao.enderecoSite,
                          (value) => !!value || 'Obrigatório.',
                          (value) => value.length > 2 || 'Preencha o endereço do site',
                          (value) => valida_url(value)
                        ]"
                        :disabled="isUpdating"
                        @change="validacao.enderecoSite = false"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.email"
                        label="E-mail"
                        maxlength="100"
                        hint="Obrigatório"
                        :rules="[
                          validacao.email == false || validacao.email,
                          (value) => !!value || 'Obrigatório.',
                          (value) => value.length > 2 || 'Preencha o e-mail',
                          (value) => valida_email(value)
                        ]"
                        :disabled="isUpdating"
                        @change="
                          site.email = site.email.toLowerCase();
                          validacao.email = false;
                        "
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs4 md2 v-if="site.idPais == 1">
                      <v-text-field
                        v-model="site.foneDDD"
                        label="DDD"
                        v-mask="'##'"
                        hint="Obrigatório"
                        :rules="[validacao.foneDDD == false || validacao.foneDDD, (value) => !!value || 'Obrigatório.', (value) => isDDDvalido(value) || 'DDD inválido']"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        @change="validacao.foneDDD = false"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs4 md2 v-if="site.idPais != 1"> </v-flex>
                    <v-flex xs8 md4>
                      <v-text-field
                        v-model="site.foneNumero"
                        label="Telefone"
                        v-mask="'###########'"
                        hint="Obrigatório"
                        :rules="[
                          validacao.foneNumero == false || validacao.foneNumero,
                          (value) => !!value || 'Obrigatório.',
                          (value) => (value != null && value.length > 7) || 'Número inválido'
                        ]"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        @change="validacao.foneNumero = false"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-text-field
                        background-color="white"
                        v-model="site.identificacao"
                        :label="'Identificação (' + (site.idPais == 1 ? 'CNPJ' : 'NIF') + ')'"
                        v-mask="'##############'"
                        hint="Obrigatório"
                        :rules="[validacao.identificacao == false || validacao.identificacao, (value) => !!value || 'Obrigatório.', (value) => valida_identificacao(value)]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.identificacao = false"
                      ></v-text-field>
                    </v-flex>
                    <!-- validações mais complexas, fazer no servidor - para não ficar escrevendo código para validar 2x (e o browser pode ser burlado) -->
                    <v-flex xs12 md4>
                      <v-text-field
                        background-color="white"
                        v-model="site.inscricaoEstadual"
                        label="Inscrição Estadual"
                        v-mask="'##############'"
                        hint="Obrigatório - use 000 para ISENTO"
                        :rules="[validacao.inscricaoEstadual == false || validacao.inscricaoEstadual, (value) => !!value || 'Obrigatório - use 000 para ISENTO']"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.inscricaoEstadual = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md4>
                      <v-text-field
                        background-color="white"
                        v-model="site.inscricaoMunicipal"
                        label="Inscrição Municipal"
                        v-mask="'####################'"
                        :disabled="isUpdating"
                        :rules="[validacao.inscricaoMunicipal == false || validacao.inscricaoMunicipal]"
                        color="blue-grey lighten-2"
                        @change="validacao.inscricaoMunicipal = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">Endereço</h4>
                      <v-divider></v-divider>
                    </v-flex>

                    <v-flex xs12 md6 v-if="site.idPais == 1">
                      <v-text-field
                        background-color="white"
                        v-model="site.cep"
                        label="CEP"
                        v-mask="'#####-###'"
                        hint="Obrigatório"
                        :rules="[validacao.cep == false || validacao.cep, (value) => !!value || 'Obrigatório.', (value) => value.length == 9 || 'Deve conter 8 dígitos numéricos']"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        append-icon="search"
                        @keyup="buscarCep"
                        @keyup.enter="buscarCep"
                        @click:append="buscarCep"
                        @change="validacao.cep = false"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 v-if="site.idPais != 1">
                      <v-text-field
                        background-color="white"
                        v-model="site.cep"
                        label="CEP"
                        v-mask="'####-###'"
                        hint="Obrigatório"
                        :rules="[validacao.cep == false || validacao.cep, (value) => !!value || 'Obrigatório.', (value) => value.length == 8 || 'Deve conter 7 dígitos numéricos']"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.cep = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6></v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="idUF"
                        :disabled="isUpdating"
                        :items="ufs"
                        hint="Obrigatório"
                        :rules="[(value) => !!value || 'Obrigatório.']"
                        :filter="filtrarObjetos"
                        :label="'Selecione o ' + (site.idPais == 1 ? 'Estado' : 'Distrito')"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="site.idMunicipio"
                        :disabled="isUpdating"
                        :items="municipios"
                        :filter="filtrarObjetos"
                        hint="Obrigatório"
                        :rules="[validacao.idMunicipio == false || validacao.idMunicipio, (value) => !!value || 'Obrigatório.']"
                        :label="'Selecione o ' + (site.idPais == 1 ? 'Município' : 'Concelho')"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.idMunicipio = false"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.bairro"
                        :label="site.idPais == 1 ? 'Bairro' : 'Freguesia/Localidade'"
                        maxlength="60"
                        hint="Obrigatório"
                        :rules="[
                          validacao.bairro == false || validacao.bairro,
                          (value) => !!value || 'Obrigatório.',
                          (value) => valida_nome(value) || (site.idPais == 1 ? 'Bairro inválido' : 'Freguesia/Localidade inválida'),
                          (value) => value.length > 1 || 'Preencha o nome ' + (site.idPais == 1 ? 'do bairro' : 'da freguesia/localidade')
                        ]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="
                          site.bairro = pLetra(site.bairro);
                          validacao.bairro = false;
                        "
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.logradouro"
                        label="Logradouro (Avenida, Rua, etc)"
                        maxlength="100"
                        hint="Obrigatório"
                        :rules="[
                          validacao.logradouro == false || validacao.logradouro,
                          (value) => !!value || 'Obrigatório.',
                          (value) => valida_nome(value) || 'Logradouro inválido',
                          (value) => value.length > 2 || 'Preencha o nome do logradouro'
                        ]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="
                          site.logradouro = pLetra(site.logradouro);
                          validacao.logradouro = false;
                        "
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.complemento"
                        label="Complemento do Endereço"
                        maxlength="100"
                        :disabled="isUpdating"
                        :rules="[validacao.complemento == false || validacao.complemento, (value) => valida_nome(value) || 'Complemento inválido']"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="
                          site.complemento = pLetra(site.complemento);
                          validacao.complemento = false;
                        "
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.numero"
                        label="Número"
                        v-mask="'#####'"
                        :rules="[validacao.numero == false || validacao.numero, (value) => parseInt(value) >= 0 || 'Número inválido']"
                        hint="Obrigatório"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.numero = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">Integração</h4>
                      <v-divider></v-divider>
                    </v-flex>

                    <v-flex xs12 md2>
                      <v-text-field
                        background-color="white"
                        v-model="site.idCRM"
                        label="Código CRM"
                        v-mask="'######'"
                        hint="Obrigatório"
                        :rules="[validacao.idCRM == false || validacao.idCRM, (value) => !!value || 'Obrigatório.', (value) => value > 0 || 'Preencha o código do site no CRM']"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.idCRM = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md2>
                      <v-text-field :value="formatDate(site.dtCriacao)" label="Data da Criação" readonly color="blue-grey lighten-2"></v-text-field>
                    </v-flex>

                    <v-flex xs6 offset-md2 md3>
                      <v-switch v-model="site.situacao" :label="`${site.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
                    </v-flex>

                    <v-flex xs6 md3>
                      <v-switch v-model="site.rede" :label="`${site.rede == 1 ? 'Site é o Gestor da REDE' : 'Site não é gestor da rede'}`"></v-switch>
                    </v-flex>

                    <v-flex xs3>
                      <v-switch v-model="site.assinaturas" :label="`${site.assinaturas == 1 ? 'Módulo Assinaturas' : 'Não possui módulo assinaturas'}`"></v-switch>
                    </v-flex>
                    <v-flex xs3>
                      <v-switch v-model="site.comercial" :label="`${site.comercial == 1 ? 'Módulo Comercial' : 'Não possui módulo comercial'}`"></v-switch>
                    </v-flex>
                    <v-flex xs3>
                      <v-switch v-model="site.editorial" :label="`${site.editorial == 1 ? 'Módulo Editorial' : 'Não possui módulo editorial'}`"></v-switch>
                    </v-flex>
                    <v-flex xs3>
                      <v-switch v-model="site.assinante" :label="`${site.assinante == 1 ? 'Módulo Assinante' : 'Não possui módulo assinante'}`"></v-switch>
                    </v-flex>
                    <v-flex xs3>
                      <v-switch v-model="site.paywall" :label="`${site.paywall == 1 ? 'Paywall - Vendas Online' : 'Não possui paywall - vendas online'}`"></v-switch>
                    </v-flex>
                    <v-flex xs3>
                      <v-switch v-model="site.flip" :label="`${site.flip == 1 ? 'Módulo Flip' : 'Não possui módulo flip'}`"></v-switch>
                    </v-flex>
                    <v-flex xs3>
                      <v-switch v-model="site.celesc" :label="`${site.celesc == 1 ? 'Celesc' : 'Não possui Celesc'}`"></v-switch>
                    </v-flex>

                    <v-flex xs4 v-if="site.identificacao == '504968483'">
                      <!-- O Mirante -->
                      <v-switch v-model="site.integracao" :label="`${site.integracao == 1 ? 'Integração Ativa' : 'Integração inativa'}`"></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Usuários do Site</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex offset-xs8>
                      <v-text-field
                        autofocus
                        background-color="white"
                        v-model="searchUsuariosSite"
                        append-icon="search"
                        label="Digite para pesquisar..."
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-data-table
                        mobile-breakpoint="0"
                        :headers="headersUsuariosSite"
                        :items="usuariosSite"
                        :search="searchUsuariosSite"
                        no-data-text
                        no-results-text
                        :footer-props="{
                          itemsPerPageText: 'Registros por página',
                          itemsPerPageOptions: [10, 25, 50],
                          pageText: ''
                        }"
                      >
                        <template v-slot:item.situacao="{ item }">
                          <span v-html="item.situacao == 1 ? 'Ativo' : '<strong>Inativo</strong>'"></span>
                        </template>

                        <template v-slot:no-results>
                          <v-alert :value="true" color="error" icon="warning" outlined>"{{ searchUsuariosSite }}" não encontrado.</v-alert>
                        </template>
                      </v-data-table>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Perfis de Acesso do Site</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex offset-xs8>
                      <v-text-field
                        autofocus
                        background-color="white"
                        v-model="searchPerfisSite"
                        append-icon="search"
                        label="Digite para pesquisar..."
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-data-table
                        mobile-breakpoint="0"
                        :headers="headersPerfisSite"
                        :items="perfisSite"
                        :search="searchPerfisSite"
                        no-data-text
                        no-results-text
                        :footer-props="{
                          itemsPerPageText: 'Registros por página',
                          itemsPerPageOptions: [10, 25, 50],
                          pageText: ''
                        }"
                      >
                        <template v-slot:item.situacao="{ item }">
                          <span v-html="item.situacao == 1 ? 'Ativo' : '<strong>Inativo</strong>'"></span>
                        </template>

                        <template v-slot:no-results>
                          <v-alert :value="true" color="error" icon="warning" outlined>"{{ searchPerfisSite }}" não encontrado.</v-alert>
                        </template>
                      </v-data-table>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.baseDados ? 'red--text' : ''">
                <strong>Base de Dados</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md6>
                      <v-text-field
                        autofocus
                        background-color="white"
                        v-model="site.nomeBanco"
                        label="Nome da Base de Dados"
                        maxlength="100"
                        :disabled="isUpdating"
                        :rules="[validacao.nomeBanco == false || validacao.nomeBanco]"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.nomeBanco = false"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        autofocus
                        background-color="white"
                        v-model="site.nomeBancoEDT"
                        label="Nome da Base de Dados do Editorial"
                        maxlength="100"
                        :disabled="isUpdating"
                        :rules="[validacao.nomeBancoEDT == false || validacao.nomeBancoEDT]"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.nomeBancoEDT = false"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.chavesExternas ? 'red--text' : ''">
                <strong>Chaves Externas</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md6>
                      <v-text-field
                        autofocus
                        background-color="white"
                        v-model="site.fbAppId"
                        label="Facebook ID"
                        type="password"
                        maxlength="32"
                        :rules="[validacao.fbAppId == false || validacao.fbAppId]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.fbAppId = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.fbAppKey"
                        label="Facebook Key"
                        type="password"
                        maxlength="32"
                        :rules="[validacao.fbAppKey == false || validacao.fbAppKey]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.fbAppKey = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.gpAppId"
                        label="Google+ ID"
                        type="password"
                        maxlength="32"
                        :rules="[validacao.gpAppId == false || validacao.gpAppId]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.gpAppId = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.gpAppKey"
                        label="Google+ Key"
                        type="password"
                        maxlength="32"
                        :rules="[validacao.gpAppKey == false || validacao.gpAppKey]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.gpAppKey = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.twAppId"
                        label="Twitter ID"
                        type="password"
                        maxlength="32"
                        :rules="[validacao.twAppId == false || validacao.twAppId]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.twAppId = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.twAppKey"
                        label="Twitter Key"
                        type="password"
                        maxlength="32"
                        :rules="[validacao.twAppKey == false || validacao.twAppKey]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.twAppKey = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.inAppId"
                        label="Instagram ID"
                        type="password"
                        maxlength="32"
                        :rules="[validacao.inAppId == false || validacao.inAppId]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.inAppId = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.inAppKey"
                        label="Instagram Key"
                        type="password"
                        maxlength="32"
                        :rules="[validacao.inAppKey == false || validacao.inAppKey]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.inAppKey = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.cieloMerchantId"
                        type="password"
                        label="Cielo Merchant Id"
                        maxlength="36"
                        :rules="[validacao.cieloMerchantId == false || validacao.cieloMerchantId]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.cieloMerchantId = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.cieloMerchantKey"
                        type="password"
                        label="Cielo Merchant Key"
                        maxlength="40"
                        :rules="[validacao.cieloMerchantKey == false || validacao.cieloMerchantKey]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.cieloMerchantKey = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.mundipaggKey"
                        type="password"
                        label="Mundipagg Key"
                        maxlength="40"
                        :rules="[validacao.mundipaggKey == false || validacao.mundipaggKey]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.mundipaggKey = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.googleCaptchaSite"
                        type="password"
                        label="Google Captcha Site"
                        maxlength="40"
                        :rules="[validacao.googleCaptchaSite == false || validacao.googleCaptchaSite]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.googleCaptchaSite = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.googleCaptchaSecret"
                        type="password"
                        label="Google Captcha Secret"
                        maxlength="40"
                        :rules="[validacao.googleCaptchaSecret == false || validacao.googleCaptchaSecret]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.googleCaptchaSecret = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.takeBlipKey"
                        type="password"
                        label="Take Blip Key"
                        maxlength="48"
                        :rules="[validacao.takeBlipKey == false || validacao.takeBlipKey]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.takeBlipKey = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.swUser"
                        type="password"
                        label="SafeWeb Identificação"
                        hint="CPF ou CNPJ de quem irá autorizar"
                        persistent-hint
                        v-mask="'##############'"
                        :rules="[validacao.swUser == false || validacao.swUser, (value) => valida_identificacaoSW(value)]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.swUser = false"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        v-model="site.swSenhaCert"
                        type="password"
                        label="SafeWeb Senha Certificado"
                        maxlength="30"
                        :rules="[validacao.swSenhaCert == false || validacao.swSenhaCert]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.swSenhaCert = false"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs11>
                      <v-textarea
                        v-model="site.firebaseKey"
                        label="Firebase Key"
                        type="password"
                        maxlength="3000"
                        counter="3000"
                        rows="1"
                        :rules="[validacao.firebaseKey == false || validacao.firebaseKey, (value) => !value || value.length > 50 || 'Cole aqui a key para conexão ao Firebase']"
                        :disabled="isUpdating || site.firebaseKey != null"
                        color="blue-grey lighten-2"
                        @change="validacao.firebaseKey = false"
                      ></v-textarea>
                    </v-flex>
                    <v-flex xs1>
                      <v-btn color="blue-grey lighten-2" fab small class="mt-4">
                        <v-icon color="white" title="Limpar Firebase Key" @click="site.firebaseKey = null">close</v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 v-if="site.firebaseKey">
                      <v-autocomplete
                        v-model="site.idUsuarioFirebase"
                        :disabled="isUpdating"
                        :items="usuariosSiteAtivos"
                        :filter="filtrarObjetos"
                        label="Usuário Firebase"
                        item-text="nome"
                        item-value="id"
                        @change="validacao.idUsuarioFirebase = false"
                        hint="Usuário que irá receber as mensagens de erro do Firebase"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <v-text-field
                        background-color="white"
                        v-model="site.urlProtheus"
                        label="Endereço (URL) do Protheus | header | token"
                        maxlength="100"
                        :disabled="isUpdating"
                        :rules="[validacao.urlProtheus == false || validacao.urlProtheus, (value) => valida_url(value, true)]"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.urlProtheus = false"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 v-if="site.urlProtheus">
                      <v-autocomplete
                        v-model="site.idUsuarioProtheus"
                        :disabled="isUpdating"
                        :items="usuariosSiteAtivos"
                        :filter="filtrarObjetos"
                        label="Usuário Protheus"
                        item-text="nome"
                        item-value="id"
                        @change="validacao.idUsuarioProtheus = false"
                        hint="Usuário que irá receber as mensagens de erro do Protheus"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <v-text-field
                        background-color="white"
                        v-model="site.tokenAsaas"
                        type="password"
                        label="Token Asaas"
                        maxlength="255"
                        :disabled="isUpdating"
                        :rules="[validacao.tokenAsaas == false || validacao.tokenAsaas]"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.tokenAsaas = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <v-text-field
                        background-color="white"
                        v-model="site.mapsApiKey"
                        type="password"
                        label="Google Maps Api Key"
                        maxlength="40"
                        :disabled="isUpdating"
                        :rules="[validacao.mapsApiKey == false || validacao.mapsApiKey]"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.mapsApiKey = false"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.cobranca ? 'red--text' : ''">
                <strong>Cobrança</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        autofocus
                        ref="focusAbaCobranca"
                        v-model="site.gateway"
                        :disabled="isUpdating"
                        :items="gateways"
                        :filter="filtrarItens"
                        label="Selecione o Gateway"
                        :rules="[validacao.gateway == false || validacao.gateway]"
                        clearable
                        no-data-text
                        @change="validacao.gateway = false"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="site.gatewayApple"
                        :disabled="isUpdating"
                        :items="gatewaysApple"
                        :filter="filtrarItens"
                        label="Selecione o Gateway Apple"
                        :rules="[validacao.gatewayApple == false || validacao.gatewayApple]"
                        clearable
                        no-data-text
                        @change="validacao.gatewayApple = false"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-switch
                        v-model="site.descricaoPeriodo"
                        :label="`${
                          site.descricaoPeriodo == 1
                            ? 'Apresenta o período da assinatura na descrição do Boleto/Recibo/NF'
                            : 'Não apresenta o período da assinatura na descrição do Boleto/Recibo/NF'
                        }`"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-switch
                        v-model="site.planoContas"
                        :label="`${site.planoContas == 1 ? 'Utiliza Plano de Contas Avançado' : 'Utiliza Plano de Contas Simplificado'}`"
                      ></v-switch>
                    </v-flex>
                    <!-- <v-flex xs12 md6>
                      <v-switch
                        v-model="site.multiCedenteBoleto"
                        :label="`${site.multiCedenteBoleto == 1 ? 'Vários cedentes de um mesmo banco para boletos' : 'Apenas 1 cedente por banco para boletos'}`"
                      ></v-switch>
                    </v-flex> -->
                    <v-flex xs12 md6>
                      <v-switch
                        v-model="site.confirmaOpPagar"
                        :label="`${site.confirmaOpPagar == 1 ? 'Exige Confirmação de Conta a Pagar' : 'Confirmação de Conta a Pagar opcional'}`"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-autocomplete
                        v-model="site.idUsuarioBanco"
                        :disabled="isUpdating"
                        :items="usuariosSiteAtivos"
                        :filter="filtrarObjetos"
                        label="Usuário Bancos"
                        item-text="nome"
                        item-value="id"
                        @change="validacao.idUsuarioBanco = false"
                        hint="Usuário que irá receber as mensagens de remessas/retornos"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-autocomplete
                        v-model="site.idUsuarioBRR"
                        :disabled="isUpdating"
                        :items="usuariosSiteAtivos"
                        :filter="filtrarObjetos"
                        label="Usuário BRR"
                        item-text="nome"
                        item-value="id"
                        @change="validacao.idUsuarioBRR = false"
                        hint="Usuário que irá receber as mensagens do BRR"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-autocomplete
                        v-model="site.idUsuarioExterno"
                        :disabled="isUpdating"
                        :items="usuariosSiteAtivos"
                        :filter="filtrarObjetos"
                        label="Usuário Externo"
                        item-text="nome"
                        item-value="id"
                        @change="validacao.idUsuarioExterno = false"
                        hint="Usuário que irá receber as mensagens de importações externas (BeAudio)"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12>
                      <v-textarea
                        v-model="site.msgCobranca"
                        label="Mensagem E-Mail e Carta Cobrança Padrão"
                        maxlength="1500"
                        counter="1500"
                        rows="10"
                        :rules="[
                          validacao.msgCobranca == false || validacao.msgCobranca,
                          (value) => (value && value.length > 50) || 'Preencha a mensagem para envio de e-mail e carta-cobrança'
                        ]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.msgCobranca = false"
                      ></v-textarea>
                    </v-flex>
                    <v-flex xs12>
                      <v-textarea
                        v-model="site.msgCobrancaASS"
                        label="Mensagem E-Mail e Carta Cobrança - Assinaturas"
                        maxlength="1500"
                        counter="1500"
                        rows="10"
                        :rules="[
                          validacao.msgCobrancaASS == false || validacao.msgCobrancaASS,
                          (value) => !value || value.length == 0 || value.length > 50 || 'Preencha a mensagem personalizada para envio de e-mail e carta-cobrança de assinaturas'
                        ]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.msgCobrancaASS = false"
                      ></v-textarea>
                    </v-flex>
                    <v-flex xs12>
                      <v-textarea
                        v-model="site.msgCobrancaANU"
                        label="Mensagem E-Mail e Carta Cobrança - Anúncios"
                        maxlength="1500"
                        counter="1500"
                        rows="10"
                        :rules="[
                          validacao.msgCobrancaANU == false || validacao.msgCobrancaANU,
                          (value) => !value || value.length == 0 || value.length > 50 || 'Preencha a mensagem personalizada para envio de e-mail e carta-cobrança de anúncios'
                        ]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.msgCobrancaANU = false"
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="elevation-0" v-if="site.assinaturas" @click="ajustaAss">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.modAssinaturas ? 'red--text' : ''">
                <strong>Módulo Assinaturas</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md4>
                      <v-text-field
                        autofocus
                        v-model="site.diasRecuperacao"
                        label="Número de Dias para Recuperação"
                        v-mask="'##'"
                        hint="Obrigatório"
                        :rules="[validacao.diasRecuperacao == false || validacao.diasRecuperacao, (value) => parseInt(value) >= 0 || 'Obrigatório.']"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        @change="validacao.diasRecuperacao = false"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-autocomplete
                        v-model="site.idUsuarioAssinaturas"
                        :disabled="isUpdating"
                        :items="usuariosSiteAtivos"
                        :filter="filtrarObjetos"
                        label="Usuário Assinaturas"
                        item-text="nome"
                        item-value="id"
                        @change="validacao.idUsuarioAssinaturas = false"
                        hint="Usuário que irá receber as mensagens de erro das assinaturas"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-currency-field
                        locale="pt-BR"
                        v-model="site.limiteTaxacao"
                        label="Limite Taxação pelo Valor Ajustado (%)"
                        :allowNegative="false"
                        :max="99"
                        :rules="[
                          validacao.limiteTaxacao == false || validacao.limiteTaxacao,
                          (value) => !!value || 'Obrigatório.',
                          (value) => (value && value.length < 6) || 'Limite inválido'
                        ]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.limiteTaxacao = false"
                      ></v-currency-field>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-text-field
                        autofocus
                        v-model="site.carteirinhaEdicao"
                        label="Número de Carteirinhas por Edição"
                        v-mask="'##'"
                        :rules="[validacao.carteirinhaEdicao == false || validacao.carteirinhaEdicao]"
                        persistent-hint
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        @change="validacao.carteirinhaEdicao = false"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-switch
                        v-model="site.cancelaFuturas"
                        :label="`${
                          site.cancelaFuturas == 1 ? 'Cancela somente parcelas futuras ao cancelar uma Assinatura*' : 'Cancela todas as parcelas ao cancelar uma Assinatura*'
                        }`"
                      ></v-switch>
                    </v-flex>

                    <v-flex xs12 md4>
                      <v-switch
                        v-model="site.titularDependente"
                        :label="`${
                          site.titularDependente == 1
                            ? 'Ao incluir Assinante, registra seus dados como Dependente'
                            : 'Ao incluir assinante, não registra seus dados como dependente'
                        }`"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-switch
                        v-model="site.assinanteRoteiro"
                        :label="`${site.assinanteRoteiro == 1 ? 'Apresenta nome do assinante no roteiro' : 'Não apresenta nome do assinante no roteiro'}`"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-switch
                        v-model="site.vip"
                        :label="`${
                          site.vip == 1
                            ? 'Apresenta INCLUÍDO/EXCLUÍDO no roteiro, quando a renovação tiver mais de um endereço de entrega'
                            : 'Não analisa renovação com mais de um endereço de entrega no roteiro'
                        }`"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-switch
                        v-model="site.repartePelaMedia"
                        :label="`${
                          site.repartePelaMedia == 1
                            ? 'Reparte pela MÉDIA (reparte padrão e últimos 3 repartes no mesmo dia da semana)'
                            : 'Reparte pelo valor padrão da banca no dia da semana'
                        }`"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs4 md2>
                      <v-text-field
                        v-model="site.diasRenovacaoAuto"
                        label="Dias Renovação Auto"
                        v-mask="'##'"
                        hint="Número de dias de antecedência para renovação automática"
                        :rules="[
                          validacao.diasRenovacaoAuto == false || validacao.diasRenovacaoAuto,
                          (value) => !!value || 'Obrigatório.',
                          (value) => (value && parseInt(value) > 1 && parseInt(value) < 31) || 'Dias inválido'
                        ]"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        @change="validacao.diasRenovacaoAuto = false"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-subheader>{{
                        site.cancelaEmLote ? "*PARCELAS SERÃO CANCELADAS MESMO SE ESTIVEREM EM LOTE." : "*Somente parcelas não incluídas em lote serão canceladas."
                      }}</v-subheader>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="elevation-0" v-if="site.comercial">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.modComercial ? 'red--text' : ''">
                <strong>Módulo Comercial</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md3>
                      <v-text-field
                        autofocus
                        v-model="site.ultimaPIauto"
                        label="Último PI Gerado Automaticamente"
                        v-mask="'######'"
                        :rules="[validacao.ultimaPIauto == false || validacao.ultimaPIauto]"
                        hint="Deixe em branco para preenchimento manual"
                        persistent-hint
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        @change="validacao.ultimaPIauto = false"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-switch v-model="site.conferePosicao" :label="site.conferePosicao == 1 ? 'Confere Posição dos Anúncios' : 'Não confere posição dos anúncios'"></v-switch>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-switch v-model="site.obsPI" :label="site.obsPI == 1 ? 'Inclui Textos das Observações na PI' : 'Não inclui observações na PI'"></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="elevation-0" v-if="site.assinante || site.paywall || site.flip">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.modAssinante ? 'red--text' : ''">
                <strong>Módulo Assinante / Paywall / FlipPage</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        autofocus
                        background-color="white"
                        v-model="site.enderecoAssinante"
                        label="Endereço (URL) do Módulo do Assinante / Paywall"
                        maxlength="100"
                        :disabled="isUpdating"
                        :rules="[validacao.enderecoAssinante == false || validacao.enderecoAssinante, (value) => valida_url(value, true)]"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.enderecoAssinante = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md3 v-if="site.flip">
                      <v-switch
                        v-model="site.flipPublico"
                        :label="`${site.flipPublico == 1 ? 'Permite Acesso Público (sem login) ao FlipPage' : 'Utiliza Acesso Autenticado ao FlipPage'}`"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md3 v-if="site.flip">
                      <v-switch
                        v-model="site.publicacaoLegal"
                        :label="`${site.publicacaoLegal == 1 ? 'Possui FlipPage de Publicações Legais' : 'Não possui flippage de publicações legais'}`"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md3 v-if="site.flip">
                      <v-text-field
                        v-model="site.resolucaoFlip"
                        label="Resolução FlipPage"
                        v-mask="'####'"
                        hint="Obrigatório"
                        :rules="[
                          validacao.resolucaoFlip == false || validacao.resolucaoFlip,
                          (value) => !!value || 'Obrigatório.',
                          (value) => parseInt(value) <= 3000 || 'Resolução inválida'
                        ]"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        @change="validacao.resolucaoFlip = false"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3 v-if="site.flip">
                      <v-text-field
                        v-model="site.nroEdicaoFlip"
                        label="Formato Número Edição FlipPage"
                        hint="Utilize: DDMMAAAA ou NNNNNNNN"
                        maxlength="8"
                        :rules="[
                          validacao.nroEdicaoFlip == false || validacao.nroEdicaoFlip,
                          (value) => !!value || 'Obrigatório.',
                          (value) => value == 'DDMMAAAA' || value == 'NNNNNNNN' || 'Formato inválido'
                        ]"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        @change="
                          site.nroEdicaoFlip = site.nroEdicaoFlip.toUpperCase();
                          validacao.nroEdicaoFlip = false;
                        "
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md3 v-if="site.assinante">
                      <v-switch v-model="site.codPromoUnico" :label="site.codPromoUnico == 1 ? 'Código Promocional Único' : 'Código Promocional Individual'"></v-switch>
                    </v-flex>
                    <v-flex xs12 md3 v-if="site.assinante">
                      <v-switch
                        v-model="site.paywallValidarRota"
                        :label="site.paywallValidarRota == 1 ? 'Validar Rota na captação online' : 'Não validar rota na captação online'"
                      ></v-switch>
                    </v-flex>

                    <v-flex xs12 md3 v-if="site.assinante">
                      <v-switch
                        v-model="site.incluirSuspensao"
                        :label="site.incluirSuspensao == 1 ? 'Assinante pode INCLUIR Suspensão' : 'Assinante não pode incluir suspensão'"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md3 v-if="site.assinante">
                      <v-switch
                        v-model="site.finalizarSuspensao"
                        :label="site.finalizarSuspensao == 1 ? 'Assinante pode FINALIZAR Suspensão' : 'Assinante não pode finalizar suspensão'"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md3 v-if="site.assinante">
                      <v-switch
                        v-model="site.reativarExpirada"
                        :label="site.reativarExpirada == 1 ? 'Assinante pode REATIVAR Assinatura EXPIRADA' : 'Assinante não pode reativar assinatura EXPIRADA'"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md3 v-if="site.assinante">
                      <v-switch
                        v-model="site.paywallIncluirDependente"
                        :label="site.paywallIncluirDependente == 1 ? 'Assinante pode Incluir Dependentes (se houver CLUBE)' : 'Assinante não pode incluir dependentes'"
                      ></v-switch>
                    </v-flex>

                    <v-flex xs6 md1 v-if="site.assinante">
                      <v-text-field
                        background-color="white"
                        v-model="site.corCab"
                        label="Cor de Fundo (Logo)"
                        maxlength="7"
                        :disabled="isUpdating"
                        :rules="[validacao.corCab == false || validacao.corCab]"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.corCab = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6 v-if="$store.state.usuario.idSitePrincipal == id">
                      <v-autocomplete
                        v-if="site.assinante"
                        v-model="site.idMotivoDegustacao"
                        :disabled="isUpdating"
                        :items="motivosCancelamento"
                        :filter="filtrarObjetos"
                        label="Motivo Cancelamento Degustação"
                        item-text="nome"
                        item-value="id"
                        @change="validacao.idMotivoDegustacao = false"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md6 v-if="$store.state.usuario.idSitePrincipal == id">
                      <v-autocomplete
                        v-if="site.assinante"
                        v-model="site.idMotivoSuspensao"
                        :disabled="isUpdating"
                        :items="motivosSuspensao"
                        :filter="filtrarObjetos"
                        label="Motivo Suspensão Paywall"
                        item-text="nome"
                        item-value="id"
                        @change="validacao.idMotivoSuspensao = false"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 v-if="site.assinante">
                      <v-text-field
                        background-color="white"
                        v-model="site.redirect_uri"
                        label="URL's Autorizadas para Direcionamento do OAuth"
                        maxlength="100"
                        :disabled="isUpdating"
                        :rules="[validacao.redirect_uri == false || validacao.redirect_uri]"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.redirect_uri = false"
                        hint="Use ; para separar as URLs"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <v-textarea
                        v-model="site.rodape"
                        label="Rodapé Novo Usuário"
                        maxlength="500"
                        counter="500"
                        rows="10"
                        :rules="[validacao.rodape == false || validacao.rodape]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.rodape = false"
                      ></v-textarea>
                    </v-flex>

                    <v-flex xs12 v-if="site.assinante">
                      <v-textarea
                        v-model="site.msgNovaAssinatura"
                        label="Mensagem E-Mail Nova Assinatura"
                        maxlength="1500"
                        counter="1500"
                        rows="10"
                        :rules="[
                          validacao.msgNovaAssinatura == false || validacao.msgNovaAssinatura,
                          (value) => !value || value.length == 0 || value.length > 50 || 'Preencha a mensagem personalizada para envio de e-mail de confirmação de assinatura'
                        ]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.msgNovaAssinatura = false"
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.modClubePremium ? 'red--text' : ''">
                <strong>Clube Premium</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md6>
                      <v-text-field
                        autofocus
                        background-color="white"
                        v-model="site.enderecoClubePremium"
                        label="Endereço (URL) do clube premium"
                        maxlength="100"
                        :disabled="isUpdating"
                        :rules="[validacao.enderecoClubePremium == false || validacao.enderecoClubePremium, (value) => valida_url(value, true)]"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @change="validacao.enderecoClubePremium = false"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.configAdicional ? 'red--text' : ''">
                <strong>Adicionais</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md3>
                      <v-switch
                        v-model="site.ocultarInativos"
                        hint="Exemplo: contas-correntes de clientes"
                        :label="site.ocultarInativos == 1 ? 'Oculta itens inativos' : 'Não oculta itens inativos'"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
    <v-card-actions id="flex-wrap">
      <v-btn to="/administrativo/sites" id="btn-rsn" color="orange lighten-2 white" style="color: white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color: white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>

      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color: white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color: white" id="btn-rsn" outlined @click="salvar('true')">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import axios from "axios";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      site: {
        id: "",
        idRede: null,
        idGrupo: null,
        idPais: 1,
        nome: "",
        sigla: "",
        razaoSocial: "",
        nomeFantasia: "",
        identificacao: "",
        inscricaoEstadual: "",
        inscricaoMunicipal: "",
        email: "",
        foneDDD: "",
        foneNumero: "",
        idMunicipio: null,
        cep: "",
        bairro: "",
        logradouro: "",
        numero: "",
        complemento: "",
        enderecoSite: "",
        dtCriacao: new Date(),
        idCRM: "",
        rede: 0,
        situacao: 1,
        assinaturas: 0,
        comercial: 0,
        editorial: 0,
        assinante: 0,
        paywall: 0,
        flip: 0,
        celesc: 0,
        enderecoAssinante: "",
        flipPublico: 0,
        publicacaoLegal: 0,
        resolucaoFlip: 300,
        nroEdicaoFlip: "NNNNNNNN",
        redirect_uri: "",
        rodape: "",
        msgNovaAssinatura: "",
        idMotivoDegustacao: null,
        idMotivoSuspensao: null,

        nomeBanco: "",
        nomeBancoEDT: "",
        fbAppId: "",
        fbAppKey: "",
        gpAppId: "",
        gpAppKey: "",
        twAppId: "",
        twAppKey: "",
        inAppId: "",
        inAppKey: "",
        cieloMerchantId: "",
        cieloMerchantKey: "",
        mundipaggKey: "",
        googleCaptchaSite: "",
        googleCaptchaSecret: "",
        takeBlipKey: "",
        swUser: null,
        swSenhaCert: "",
        firebaseKey: "",
        idUsuarioFirebase: null,
        urlProtheus: null,
        idUsuarioProtheus: null,
        tokenAsaas: null,
        mapsApiKey: null,
        gateway: "",
        gatewayApple: "",
        descricaoPeriodo: 1,
        planoContas: 0,
        cancelaFuturas: 0,
        titularDependente: 0,
        carteirinhaEdicao: 1,
        vip: 0,
        repartePelaMedia: 0,
        diasRenovacaoAuto: 14,
        idUsuarioAssinaturas: null,
        limiteTaxacao: 0,
        assinanteRoteiro: 0,
        multiCedenteBoleto: 0,
        idUsuarioBanco: null,
        idUsuarioExterno: null,
        idUsuarioBRR: null,
        confirmaOpPagar: 0,
        diasRecuperacao: 30,
        codPromoUnico: 0,
        paywallValidarRota: 0,
        incluirSuspensao: 0,
        finalizarSuspensao: 0,
        reativarExpirada: 0,
        paywallIncluirDependente: 0,
        corCab: "#fff",
        ultimaPIauto: null,
        conferePosicao: 0,
        obsPI: 0,
        ocultarInativos: 0,
        msgCobranca: `Prezado Senhor (a),

Nosso controle de pagamentos acusa, em sua conta, prestação vencida, motivo pelo qual pedimos a V. Sa. sua imediata regularização.

Tendo em vista que a emissão deste aviso é automática, caso V. Sa. já tenha efetuado o pagamento, solicitamos desconsiderá-lo.

Atenciosamente,
Depto Financeiro`,
        msgCobrancaASS: "",
        msgCobrancaANU: "",
        enderecoClubePremium: "",
        integracao: 0
      },
      validacao: {
        idRede: false,
        idGrupo: false,
        idPais: false,
        nome: false,
        sigla: false,
        razaoSocial: false,
        nomeFantasia: false,
        identificacao: false,
        inscricaoEstadual: false,
        inscricaoMunicipal: false,
        email: false,
        foneDDD: false,
        foneNumero: false,
        idMunicipio: false,
        cep: false,
        bairro: false,
        logradouro: false,
        numero: false,
        complemento: false,
        enderecoSite: false,
        idCRM: false,
        rede: false,
        situacao: false,
        assinaturas: false,
        comercial: false,
        editorial: false,
        assinante: false,
        paywall: false,
        flip: false,
        celesc: false,
        dadosBasicos: false,

        enderecoAssinante: false,
        flipPublico: false,
        publicacaoLegal: false,
        resolucaoFlip: false,
        nroEdicaoFlip: false,
        redirect_uri: false,
        rodape: false,
        msgNovaAssinatura: false,
        idMotivoDegustacao: false,
        idMotivoSuspensao: false,
        modAssinante: false,

        enderecoClubePremium: false,
        modClubePremium: false,

        configAdicional: false,

        nomeBanco: false,
        nomeBancoEDT: false,

        fbAppId: false,
        fbAppKey: false,
        gpAppId: false,
        gpAppKey: false,
        twAppId: false,
        twAppKey: false,
        inAppId: false,
        inAppKey: false,
        cieloMerchantId: false,
        cieloMerchantKey: false,
        mundipaggKey: false,
        googleCaptchaSite: false,
        googleCaptchaSecret: false,
        takeBlipKey: false,
        swUser: false,
        swSenhaCert: false,
        firebaseKey: false,
        idUsuarioFirebase: false,
        urlProtheus: false,
        idUsuarioProtheus: false,
        tokenAsaas: false,
        mapsApiKey: false,
        chavesExternas: false,

        gateway: false,
        gatewayApple: false,
        descricaoPeriodo: false,
        planoContas: false,
        msgCobranca: false,
        msgCobrancaASS: false,
        msgCobrancaANU: false,
        cobranca: false,
        cancelaFuturas: false,
        titularDependente: false,
        carteirinhaEdicao: false,
        vip: false,
        repartePelaMedia: false,
        diasRenovacaoAuto: false,
        idUsuarioAssinaturas: false,
        limiteTaxacao: false,
        assinanteRoteiro: false,
        multiCedenteBoleto: false,
        idUsuarioBanco: false,
        idUsuarioExterno: false,
        idUsuarioBRR: false,
        confirmaOpPagar: false,

        modAssinaturas: false,
        diasRecuperacao: false,
        codPromoUnico: false,
        paywallValidarRota: false,
        incluirSuspensao: false,
        finalizarSuspensao: false,
        reativarExpirada: false,
        paywallIncluirDependente: false,
        corCab: false,

        modComercial: false,
        ultimaPIauto: false,
        conferePosicao: false,
        obsPI: false,

        mensagemErro: false,
        integracao: false
      },

      paises: [],
      usuariosSite: [],
      usuariosSiteAtivos: [],
      perfisSite: [],
      idUF: null,
      redes: [],
      grupos: [],
      ufs: [],
      municipios: [],
      motivosCancelamento: [],
      motivosSuspensao: [],

      gateways: ["CIELO", "CIELO_SANDBOX", "MUNDIPAGG", "MUNDIPAGG_SANDBOX", "ASAAS", "ASAAS_SANDBOX"],
      gatewaysApple: ["SANDBOX", "PRODUCTION"],

      headersUsuariosSite: [
        { text: "ID", value: "id" },
        { text: "Login", value: "login" },
        { text: "Nome", value: "nome" },
        { text: "Situação", value: "situacao" }
      ],
      headersPerfisSite: [
        { text: "ID", value: "id" },
        { text: "Módulo", value: "modulo" },
        { text: "Nome", value: "nome" },
        { text: "Situação", value: "situacao" }
      ],

      campo: "",
      elementoValid: true,
      id: "",
      searchUsuariosSite: "",
      searchPerfisSite: "",
      painel: 0,
      showSenha: false,
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);

      if (!this.$refs.formElemento.validate()) {
        this.ajustaValidacao();
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      this.retorno = "";

      this.site.dtAlteracao = new Date();
      this.site.dtAlteracao = new Date(this.site.dtAlteracao.getTime() - this.site.dtAlteracao.getTimezoneOffset() * 60 * 1000);

      if (!this.site.ultimaPIauto) {
        this.site.ultimaPIauto = null;
      }
      if (!this.site.swUser) {
        this.site.swUser = null;
      }
      if (!this.site.urlProtheus) {
        this.site.urlProtheus = null;
      }
      if (this.$store.state.usuario.idSitePrincipal != this.id) {
        delete this.site["idMotivoDegustacao"];
        delete this.site["idMotivoSuspensao"];
      }

      var siteCommit = Object.assign({}, this.site);
      siteCommit.cep = util.somenteNumeros(siteCommit.cep);
      try {
        if (this.id == "") {
          const response = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/site", siteCommit);
          //* Define que agora o site já foi para a base e está em edição
          this.id = response.data.id;
          this.site.id = response.data.id;
        } else {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/site/" + this.id, siteCommit);
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/administrativo/site");
          this.$router.go("/administrativo/site");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/administrativo/sites");
          }, 2000);
        } else {
          //* Necessita recarregar para atualizar permissões
          this.$router.push("/administrativo/site/" + this.id);
          this.$router.go("/administrativo/site/" + this.id);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.ajustaValidacao();
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    //função realiza focus
    focusBasicos() {
      setTimeout(() => {
        this.$refs.focusBasico.focus();
      }, 500);
    },
    focusAssinante() {
      setTimeout(() => {
        this.$refs.focusAbaAssinante.focus();
      }, 500);
    },
    focusDados() {
      setTimeout(() => {
        this.$refs.focusAbaDados.focus();
      }, 500);
    },
    focusArquivos() {
      setTimeout(() => {
        this.$refs.focusAbaArquivos.focus();
      }, 500);
    },
    focusExterna() {
      setTimeout(() => {
        this.$refs.focusAbaExterna.focus();
      }, 500);
    },
    focusSmtp() {
      setTimeout(() => {
        this.$refs.focusAbaSmtp.focus();
      }, 500);
    },
    focusCobranca() {
      setTimeout(() => {
        this.$refs.focusAbaCobranca.focus();
      }, 500);
    },

    async carregarUFs() {
      try {
        //* Carrega as UFs do país
        const responseUFs = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/uf/lista?idPais=" + this.site.idPais);
        this.ufs = responseUFs.data;
        if (this.site.idPais != 1) {
          this.ufs.forEach((element) => {
            element.nome += "/" + element.pais;
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de " + (this.site.idPais == 1 ? "UFs" : "Distritos");
        this.snackErro = true;
      }
    },

    async carregarMunicipios() {
      try {
        //* Carrega os municípios da UF
        const responseMunicipios = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/municipio/lista?idUF=" + this.idUF);
        this.municipios = responseMunicipios.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de " + (this.site.idPais == 1 ? "municípios da UF" : "concelhos do distrito");
        this.snackErro = true;
      }
    },

    buscarCep() {
      if (this.site.cep != null && this.site.cep.length == 9) {
        this.idUF = null;
        this.site.idMunicipio = null;
        this.site.bairro = "";
        this.site.logradouro = "";

        //* Busca o cep no webservice gratuito, tem que ser axios, senão dá erro crossorigin
        axios
          .get("https://viacep.com.br/ws/" + this.site.cep + "/json/")
          .then((response) => {
            var cep = response.data;
            if (cep.erro) {
              this.mensagem = "CEP não localizado";
              this.snackErro = true;
            } else {
              //* Busca o município pelo código IBGE
              api
                .get(process.env.VUE_APP_URL_WEB_API + "adm/municipio/ibge/" + cep.ibge)
                .then((response) => {
                  var municipio = response.data[0];
                  if (municipio != null && municipio != undefined) {
                    this.idUF = municipio.idUF;
                    this.carregarMunicipios(municipio.idUF);
                    this.site.idMunicipio = municipio.id;
                    this.site.bairro = cep.bairro;
                    this.site.logradouro = cep.logradouro;
                  }
                })
                .catch(() => {
                  this.isUpdating = true;
                  this.mensagem = "Ocorreu um erro ao carregar a lista de " + (this.site.idPais == 1 ? "municípios da UF" : "concelhos do distrito");
                  this.snackErro = true;
                });
            }
          })
          .catch(() => {
            this.isUpdating = true;
            this.mensagem = "Ocorreu um erro ao buscar os dados do cep";
            this.snackErro = true;
          });
      }
    },
    ajustaValidacao() {
      if (
        this.validacao.idRede ||
        this.validacao.idGrupo ||
        this.validacao.nome ||
        this.validacao.razaoSocial ||
        this.validacao.nomeFantasia ||
        this.validacao.identificacao ||
        this.validacao.inscricaoEstadual ||
        this.validacao.inscricaoMunicipal ||
        this.validacao.email ||
        this.validacao.idMunicipio ||
        this.validacao.cep ||
        this.validacao.bairro ||
        this.validacao.logradouro ||
        this.validacao.numero ||
        this.validacao.complemento ||
        this.validacao.enderecoSite ||
        this.validacao.idCRM ||
        this.validacao.rede ||
        this.validacao.situacao ||
        this.validacao.assinaturas ||
        this.validacao.comercial ||
        this.validacao.editorial ||
        this.validacao.assinante ||
        this.validacao.paywall ||
        this.validacao.flip ||
        this.validacao.celesc
      ) {
        this.validacao.dadosBasicos = true;
      }
      if (
        this.validacao.enderecoAssinante ||
        this.validacao.flipPublico ||
        this.validacao.publicacaoLegal ||
        this.validacao.resolucaoFlip ||
        this.validacao.nroEdicaoFlip ||
        this.validacao.rodape ||
        this.validacao.msgNovaAssinatura ||
        this.validacao.idMotivoDegustacao ||
        this.validacao.idMotivoSuspensao ||
        this.validacao.redirect_uri ||
        this.validacao.corCab
      ) {
        this.validacao.modAssinante = true;
      }
      if (this.validacao.enderecoClubePremium) {
        this.validacao.modClubePremium = true;
      }
      if (this.validacao.ocultarInativos) {
        this.validacao.configAdicional = true;
      }
      if (this.validacao.nomeBanco || this.validacao.nomeBancoEDT) {
        this.validacao.baseDados = true;
      }
      if (
        this.validacao.fbAppId ||
        this.validacao.fbAppKey ||
        this.validacao.gpAppId ||
        this.validacao.gpAppKey ||
        this.validacao.twAppId ||
        this.validacao.twAppKey ||
        this.validacao.inAppId ||
        this.validacao.inAppKey ||
        this.validacao.cieloMerchantId ||
        this.validacao.cieloMerchantKey ||
        this.validacao.mundipaggKey ||
        this.validacao.googleCaptchaSite ||
        this.validacao.googleCaptchaSecret ||
        this.validacao.takeBlipKey ||
        this.validacao.swUser ||
        this.validacao.swSenhaCert ||
        this.validacao.firebaseKey ||
        this.validacao.idUsuarioFirebase ||
        this.validacao.urlProtheus ||
        this.validacao.idUsuarioProtheus ||
        this.validacao.tokenAsaas ||
        this.validacao.mapsApiKey
      ) {
        this.validacao.chavesExternas = true;
      }
      if (
        this.validacao.gateway ||
        this.validacao.gatewayApple ||
        this.validacao.descricaoPeriodo ||
        this.validacao.planoContas ||
        this.validacao.msgCobranca ||
        this.validacao.msgCobrancaASS ||
        this.validacao.msgCobrancaANU ||
        this.validacao.multiCedenteBoleto ||
        this.validacao.idUsuarioBanco ||
        this.validacao.idUsuarioExterno ||
        this.validacao.idUsuarioBRR ||
        this.validacao.confirmaOpPagar
      ) {
        this.validacao.cobranca = true;
      }
      if (
        this.validacao.diasRecuperacao ||
        this.validacao.cancelaFuturas ||
        this.validacao.titularDependente ||
        this.validacao.carteirinhaEdicao ||
        this.validacao.vip ||
        this.validacao.repartePelaMedia ||
        this.validacao.diasRenovacaoAuto ||
        this.validacao.idUsuarioAssinaturas ||
        this.validacao.limiteTaxacao ||
        this.validacao.assinanteRoteiro
      ) {
        this.validacao.modAssinaturas = true;
      }
      if (this.validacao.ultimaPIauto || this.validacao.confereP || this.validacao.obsPI) {
        this.validacao.modComercial = true;
      }
    },
    removeRede() {
      this.site.idRede = null;
    },
    removeGrupo() {
      this.site.idGrupo = null;
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    isDDDvalido(ddd) {
      return util.isDDDvalido(ddd);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    valida_identificacao(text) {
      if (this.site.idPais == 1) {
        if (text.length != 14) {
          return "Identificação deve ter 14 dígitos";
        } else {
          return util.valida_cnpj(text) ? true : "CNPJ inválido";
        }
      } else {
        if (text.length != 9) {
          return "Identificação deve ter 9 dígitos";
        } else {
          return util.valida_nif(text) ? true : "NIF inválido";
        }
      }
    },
    valida_identificacaoSW(text) {
      if (text) {
        if (text.length != 14 && text.length != 11) {
          return "Identificação deve ter 11 ou 14 dígitos";
        } else {
          return util.valida_cnpj(text) || util.valida_cpf(text) ? true : "Identificação inválida";
        }
      } else {
        return true;
      }
    },
    valida_email(text) {
      if (!util.valida_email(text)) {
        return "E-mail inválido.";
      } else {
        return true;
      }
    },
    valida_url(url, assinante) {
      if (url && !util.valida_url(url)) {
        return assinante && !url ? true : "Endereço inválido.";
      } else {
        return true;
      }
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    ajustaAss() {
      var temp = this.site.limiteTaxacao;
      this.site.limiteTaxacao = "0,00";
      setTimeout(() => {
        this.site.limiteTaxacao = temp;
      }, 300);
    }
  },

  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA"]);

    try {
      //* Carrega as redes
      const responseRedes = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/rede/all");
      this.redes = responseRedes.data;
    } catch {
      this.isUpdating = true;
      this.mensagem = "Ocorreu um erro ao carregar a lista de redes de sites";
      this.snackErro = true;
    }

    try {
      //* Carrega os grupos
      const responseGrupos = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/grupo/lista");
      this.grupos = responseGrupos.data;
    } catch {
      this.isUpdating = true;
      this.mensagem = "Ocorreu um erro ao carregar a lista de grupos de sites";
      this.snackErro = true;
    }

    try {
      //* Carrega os países
      const responsePaises = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/pais/lista");
      this.paises = responsePaises.data;
    } catch {
      this.isUpdating = true;
      this.mensagem = "Ocorreu um erro ao carregar a lista de países";
      this.snackErro = true;
    }

    if (this.$store.state.usuario.idSitePrincipal == this.id && this.site.assinante) {
      try {
        //* Carrega os motivos de cancelamento - somente se estiver editando o respectivo site
        const responseMotivos = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/motivo-cancelamento/lista");
        this.motivosCancelamento = responseMotivos.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de motivos de cancelamento";
        this.snackErro = true;
      }
      try {
        //* Carrega os motivos de suspensão - somente se estiver editando o respectivo site
        const responseMotivosSusp = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/motivo-suspensao/lista");
        this.motivosSuspensao = responseMotivosSusp.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de motivos de suspensao";
        this.snackErro = true;
      }
    }

    //* Se é edição, carrega o site e seus usuários
    if (this.id != "") {
      try {
        //* Carrega o site
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/site/" + this.id);

        if (!response.data) {
          this.$router.push("/administrativo/sites");
          return;
        }
        this.site = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados do site";
        this.snackErro = true;
      }

      if (!this.site.msgCobranca) {
        this.site.msgCobranca = `Prezado Senhor(a) #CLIENTE#,

Nosso controle de pagamentos acusa, em sua conta, prestação vencida, motivo pelo qual pedimos a V. Sa. sua imediata regularização.

Tendo em vista que a emissão deste aviso é automática, caso V. Sa. já tenha efetuado o pagamento, solicitamos desconsiderá-lo.

Atenciosamente,
Depto Financeiro`;
      }

      //* Carrega os usuários do site
      api
        .get(process.env.VUE_APP_URL_WEB_API + "adm/site-usuario/usuariosSite/" + this.site.id)
        .then((response) => {
          this.usuariosSite = response.data;
          this.usuariosSite.forEach((element) => {
            if (element.situacao) {
              this.usuariosSiteAtivos.push(element);
            }
          });
        })
        .catch(() => {
          this.isUpdating = true;
          this.mensagem = "Ocorreu um erro ao carregar a lista de usuários do site";
          this.snackErro = true;
        });

      //* Carrega os perfis de acesso do site
      api
        .get(process.env.VUE_APP_URL_WEB_API + "adm/perfil/site/" + this.site.id)
        .then((response) => {
          this.perfisSite = response.data;
        })
        .catch(() => {
          this.isUpdating = true;
          this.mensagem = "Ocorreu um erro ao carregar a lista de perfis de acesso do site";
          this.snackErro = true;
        });

      //* Define a UF do site e carrega seus municípios
      api
        .get(process.env.VUE_APP_URL_WEB_API + "adm/municipio/" + this.site.idMunicipio)
        .then((response) => {
          this.idUF = response.data.idUF;
          this.carregarMunicipios(this.idUF);
        })
        .catch(() => {
          this.isUpdating = true;
          this.mensagem = "Ocorreu um erro ao carregar " + (this.site.idPais == 1 ? "a UF do município" : "o distrito do concelho");
          this.snackErro = true;
        });
    }
    await this.carregarUFs();

    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  },
  watch: {
    idUF() {
      this.carregarMunicipios();
    }
  }
};
</script>
